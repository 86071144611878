import React from 'react';
import clsx from 'clsx';

import './SpinTheWheel.scss';

import leafLeft from './img/leaf-left.png';
import leafRight from './img/leaf-right.png';
import wheelShadow from './img/wheel-shadow.png';
import trophy from '../../../../../resources/img/trophy-simple.png';

const SpinTheWheelComponent = (props) => {
	const wheelVars = {
		'--nb-item': props.playGetPregame.prizes.length,
		'--selected-item': props.selectedItem,
	};
	const spinning = props.selectedItem !== null ? 'spinning' : '';

	const renderItems = () => {
		return props.playGetPregame.prizes.map((prize, index) => {
			return (
				<div
					className="wheel-item"
					key={index}
					style={{ '--item-nb': index }}>
					<p>
						<strong>{prize.texts[props.lang][0]}</strong>
						<span>{prize.texts[props.lang][1]}</span>
						{prize.texts[props.lang][2]}
					</p>
				</div>
			)
		})
	}

	return (
		<div className="spin-pregame" style={{ '--spinning-duration': `${props.spinDuration}s` }}>
			<div className="title">
				<h2>{props.playGetPregame.name[props.lang]}</h2>
				<p>{props.playGetPregame.subtitle[props.lang]}</p>
			</div>
			<div className="game-wrapper">
				<img src={leafLeft} alt="" className="bg-left"/>
				<img src={leafRight} alt="" className="bg-right"/>
				<div className="wheel-container">
					<img src={wheelShadow} alt="" className="wheel-shadow"/>
					<div className={clsx('spin-tap', spinning && 'hide')}></div>
					<div className={clsx("wheel-prize", props.showPrize ? "active" : "inactive")}>
						<div className="content-prize">
							<img src={trophy} alt=""/>
							<h3>{props.prize.texts[props.lang][0]}</h3>
							<p>{props.prize.texts[props.lang][1]}</p>
						</div>
					</div>
					<div className={clsx("wheel", spinning, props.showPrize ? "inactive" : "active")} style={wheelVars} onClick={props.selectItem}>
						{renderItems()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpinTheWheelComponent;
