import React from 'react';
import getText from '../../../../utils/language';
import {Link} from 'react-router-dom';

import './PlayerStats.scss';

import iconNewbie from '../../../../resources/img/icons-level/icon-newbie.png'
import iconSkilled from '../../../../resources/img/icons-level/icon-skilled.png'
import iconPro from '../../../../resources/img/icons-level/icon-pro.png'
import iconCoin from '../../../../resources/img/icon-coin.png';
import iconGuru from '../../../../resources/img/icons-level/icon-guru.png';
import iconHome from './img/icon-home.png';

const PlayerStatsComponent = (props) => {

	let iconLevel = '';

	switch(props.userInfo.level) {
		case 1:
			iconLevel = iconNewbie;
			break;
		case 2:
			iconLevel = iconPro;
			break;
		case 3:
			iconLevel = iconSkilled;
			break;
		case 4:
			iconLevel = iconGuru;
			break;
	}

	return (
		<div className="player-stats-component">
			<div className="stats-item left">
				<div className="icon-wrapper">
					<img src={iconLevel} alt="Points"/>
				</div>
				<div className="stats-info">
					<span>{props.userInfo.points}</span>
					<span className="small">{getText('Points')}</span>
				</div>
			</div>
			<Link to="/play" className="home-button">
				<img src={iconHome} alt="Home"/>
			</Link>
			<div className="stats-item right">
				<div className="stats-info">
					<span>{props.userInfo?.eligibility?.credits || 0}</span>
					<span className="small">{getText('Credits')}</span>
				</div>
				<div className="icon-wrapper">
					<img src={iconCoin} alt="Credits"/>
				</div>
			</div>
		</div>
	);
};

export default PlayerStatsComponent;
