import React, { Component } from 'react';
import { connect } from 'react-redux';

import Leaderboard from "./LeaderboardComponent";
import Loader from '../../common/loader/Loader';

import { userLeaderBoard} from '../user/store/selectors';
import { userLoadLeaderBoard} from "../user/store/actions";

const stateToProps = state => ({
	userLeaderBoard: userLeaderBoard(state),
});

const actionsToProps = dispatch => ({
	userLoadLeaderBoard: () => dispatch(userLoadLeaderBoard()),
});

@connect(stateToProps, actionsToProps)
class LeaderboardContainer extends Component {
    state = {
        users: []
    }

    componentDidMount() {
    	this.props.userLoadLeaderBoard();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
    	if(prevProps.userLeaderBoard !== this.props.userLeaderBoard) {
    		this.setState({
				users: this.props.userLeaderBoard
			})
		}
	}

	render() {
    	if(this.state.users.length === 0) {
    		return <Loader/>
		}

        return (
            <div>
                <Leaderboard {...this.state}/>
            </div>
        );
    }
}

export default LeaderboardContainer;
