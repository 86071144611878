import Observable from 'Observable';
import {
	put, fork, race, take, takeLatest, all, takeEvery, call, delay, select, putResolve
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { push, replace, LOCATION_CHANGE } from 'connected-react-router';
import ls from 'local-storage';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

import { getValue } from 'AppUtils/objects';
import { adjustToLink } from 'AppUtils/url';
import { apiGet, apiPost } from 'AppUtils/api';
import logger from 'AppUtils/logging';
import { v4 as uuidv4 } from 'uuid';

import authSagas from '../components/auth/store/sagas';
import userSagas from '../components/user/store/sagas';
import playSagas from '../components/play/store/sagas';

import * as COMMON from './types';
import * as AUTH from '../components/auth/store/types';
import * as USER from '../components/user/store/types';
import * as PLAY from '../components/play/store/types';
import { appGetConfig } from "./selectors";


function getUuid() {
	try {
		return ls.get('uuid');
	} catch (e) {
		return '';
	}
}

function setUuid(uuid) {
	try {
		ls.set('uuid', uuid);
		return uuid;
	} catch (e) {
		return '';
	}
}

/**
 * This is usually called once
 */
function* onInitialize() {

	let uuid = getUuid();

	if (!uuid) {
		uuid = setUuid(uuidv4());
	}

	yield put({ type: AUTH.AUTH_SET_UUID, payload: { uuid } });

	// fork the auth checker
	yield fork(function* () {
		yield put({ type: AUTH.AUTH_CHECK });
	});

	// Wait for the auth_check to issue set or reset
	const { set, reset } = yield race({
		set: take(AUTH.AUTH_SET),
		reset: take(AUTH.AUTH_RESET),
	});

	// Before considering the application ready
	const ready = yield preReady();

	if (ready) {
		// Mark the app as ready
		yield put({ type: COMMON.APP_STATUS, payload: 'ready' });

		// Launch data refreshers
		yield fork(dataRefresher);
	} else {
		yield put({ type: COMMON.APP_STATUS, payload: 'error' });
	}

	yield fork(postReady);
}

function* dataRefresher() {
	yield [
	];
}

function getLang() {
	console.log('getLang', ls.get('lang'));
	return ls.get('lang') || process.env.REACT_APP_DEFAULT_LANGUAGE;
}

function setLang(lang) {
	ls.set('lang', lang);
}

function* appLoadTranslations(action) {
	// get interface lang
	const lang = getValue(action, 'payload.lang', getLang());
	const appConfig = yield select(appGetConfig);

	setLang(lang);

	yield putResolve({
		type: COMMON.APP_SET_TRANSLATIONS,
		payload: {
			interfaceLang: lang,
			__store: appConfig.texts && appConfig.texts[lang] ? appConfig.texts[lang] : '',
		},
	});
}

function* callApiInit() {
	let uuid = getUuid();

	let retry = 3;
	let config = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/init`, { uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				config = response;
			} else {
				msg = response.error;
			}
		});

	return { config, msg, statusCode };
}

function* appLoadSessionArchive() {
	// The session/archive method should NEVER be called programatically as it will cause
	// a lot of weird behaviour in testing; not to mention if the env is not set correctly
	// while transitioning from staging to production
}

function* preReady() {
	const { config, msg, statusCode } = yield callApiInit();

	yield putResolve({ type: COMMON.APP_SET_CONFIG, payload: { config, msg, statusCode }});

	if (config.texts) {
		yield putResolve({ type: COMMON.APP_LOAD_TRANSLATIONS });

		return true;
	}
}

function* postReady() {
	yield put({ type: USER.USER_LOAD_EVENT, payload: { type: 'APP_LAUNCH' } });
}

function* appWatchInitialize() {
	yield takeLatest(COMMON.APP_INITIALIZE, onInitialize);
	yield takeLatest(COMMON.APP_LOAD_TRANSLATIONS, appLoadTranslations);
	yield takeLatest(COMMON.APP_LOAD_SESSION_ARCHIVE, appLoadSessionArchive);

	//take all mf
	yield takeEvery('*', function* (action) {
		const state = yield select();

		if(getValue(action, 'payload.statusCode') == 500 && getValue(state, 'app.statusCode') != 500) {
			yield put({ type: COMMON.APP_STATUS, payload: 'error' });
		}

		if (getValue(action, 'payload.statusCode') && getValue(state, 'app.statusCode') != getValue(action, 'payload.statusCode')) {
			yield put({ type: COMMON.APP_SET_STATUS_CODE, payload: { statusCode: action.payload.statusCode } });
		}
	})
}

const appSagas = function* () {
	yield fork(appWatchInitialize);
};

/** END APP * */

/** START UI * */
export function* onLocationChange(action) {
	const section = 'main';

	const { payload } = action;

	if (payload) {
		const { state } = payload;
		// redirect to 404 if error404: true, before any initial location
		if (getValue(state, 'error404')) {
			yield put(push('/error/404'));
		}
	}


	yield put({
		type: COMMON.UI_URL,
		payload: {
			pathname: window.location.pathname,
			search: window.location.search,
			hash: window.location.hash,
		},
	});

	if (window.isReactGAInitialized) {
		// ReactGA.send({ hitType: "pageview", page: window.location.pathname });
	}

	yield setInitialLocation(action.payload.pathname);
}

export function* setInitialLocation(pathname = '') {
	let section = '';
	let subsection = '';

	if (!pathname) {
		pathname = window.location.pathname;
	}

	if (pathname.indexOf('/') == 0 || pathname.indexOf('/') == 3) section = 'index';

	if (pathname.indexOf('/auth') == 0 || pathname.indexOf('/auth') == 3) section = 'auth';
	else if (pathname.indexOf('/signin') == 0 || pathname.indexOf('/signin') == 3) section = 'signin';
	else if (pathname.indexOf('/signup') == 0 || pathname.indexOf('/signup') == 3) section = 'signup';
	else if (pathname.indexOf('/forgot-password') == 0 || pathname.indexOf('/forgot-password') == 3) section = 'forgot-password';
	else if (pathname.indexOf('/unsubscribe') == 0 || pathname.indexOf('/unsubscribe') == 3) section = 'unsubscribe';
	else if (pathname.indexOf('/about') == 0 || pathname.indexOf('/about') == 3) section = 'about';
	else if (pathname.indexOf('/leaderboard') == 0 || pathname.indexOf('/leaderboard') == 3) section = 'leaderboard';
	else if (pathname.indexOf('/tos') == 0 || pathname.indexOf('/tos') == 3) section = 'tos';
	else if (pathname.indexOf('/faq') == 0 || pathname.indexOf('/faq') == 3) section = 'faq';
	else if (pathname.indexOf('/play') == 0 || pathname.indexOf('/play') == 3) section = 'play';
	else if (pathname.indexOf('/result') == 0 || pathname.indexOf('/result') == 3) section = 'result';
	else if (pathname.indexOf('/profile') == 0 || pathname.indexOf('/profile') == 3) section = 'profile';
	else if (pathname.indexOf('/edit-profile') == 0 || pathname.indexOf('/edit-profile') == 3) section = 'edit-profile';
	else if (pathname.indexOf('/restricted') == 0 || pathname.indexOf('/restricted') == 3) section = 'restricted';
	else if (pathname.indexOf('/confirm') == 0 || pathname.indexOf('/confirm') == 3) section = 'restricted';
	else if (pathname.indexOf('/transactions-history') == 0 || pathname.indexOf('/transactions-history') == 3) section = 'transactions';
	else if (pathname.indexOf('/watch-video') == 0 || pathname.indexOf('/watch-video') == 3) section = 'watch-video';
	else if (pathname.indexOf('/prizes') == 0 || pathname.indexOf('/prizes') == 3) section = 'prizes';
	else if (pathname.indexOf('/levels') == 0 || pathname.indexOf('/levels') == 3) section = 'levels';

	if (pathname) {
		const subsectionArr = pathname.split('/');
		if (Array.isArray(subsectionArr)) {
			subsection = subsectionArr.pop();
		}

		if (subsectionArr.length <= 2 && section === subsection) {
			subsection = 'home';
		}
	}

	// check for URL params and if true check for saved paths
	if (!window.location.search) {
		const filtersState = ls.get('filtersState');
		const redirectFlag = ls.get('redirectFlag');
		const keyState = `${section}\\${subsection}`;

		// Uh check for local storage and then redirect
		if (filtersState && filtersState[keyState] && !redirectFlag) {
			const redirectTo = `${window.location.pathname}${filtersState[keyState]}`;
			logger.info(`gently redirected to: ${redirectTo}`);

			const history = createBrowserHistory();

			yield put(push({
				pathname: redirectTo,
				state: getValue(history, 'location.state', null)
			}));
			ls.set('redirectFlag', 1);
		} else {
			ls.set('redirectFlag', 0);
		}
	}

	yield put({ type: COMMON.UI_SECTION_CHANGE, payload: { section, subsection } });
}

export function* onRedirect(action) {
	const to = adjustToLink(action.payload);

	yield put(push(to));
}

export function* onReplace(action) {
	const to = adjustToLink(action.payload);
	yield put(replace(to));
}

function* monitorResolution() {
	let defaultSize = '';

	function watcher() {
		return eventChannel(emitter => {
			const subscr$ = Observable.create(observer => {

				const callback = (e, size) => {
					if (e.matches && size) {
						defaultSize = size;
						observer.next(size);
					}
				};

				const xs = window.matchMedia('(max-width: 575px)');
				callback(xs, 'xs');
				xs.addListener((e) => callback(e, 'xs'));

				const sm = window.matchMedia('(min-width: 576px) and (max-width: 767px)');
				callback(sm, 'sm');
				sm.addListener((e) => callback(e, 'sm'));

				const md = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
				callback(md, 'md');
				md.addListener((e) => callback(e, 'md'));

				const lg = window.matchMedia('(min-width: 992px) and (max-width: 1199px)');
				callback(lg, 'lg');
				lg.addListener((e) => callback(e, 'lg'));

				const xl = window.matchMedia('(min-width: 1200px)');
				callback(xl, 'xl');
				xl.addListener((e) => callback(e, 'xl'));
			})
				.map(computeScreenInfo)
				.catch(e => {
					return Observable.of(computeScreenInfo());
				})
				.subscribe(emitter);
			return () => {
				subscr$.unsubscribe();
			};
		});
	}

	const chan = yield call(watcher);
	try {
		let info = computeScreenInfo(defaultSize);
		yield put({ type: COMMON.UI_DEVICE_INFO, payload: info });
		while (true) {
			info = yield take(chan);
			yield put({ type: COMMON.UI_DEVICE_INFO, payload: info });
		}
	} finally {
	}
}

function computeScreenInfo(size) {
	return {
		size,
		innerWidth: window.innerWidth,
		isMobile: size === 'sm' || size === 'xs',
	};
}

function* uiWatchInitialize() {
	yield fork(monitorResolution);
	yield takeEvery(LOCATION_CHANGE, onLocationChange);
	yield takeLatest(COMMON.UI_REDIRECT, onRedirect);
	yield takeLatest(COMMON.UI_REPLACE, onReplace);
}

const uiSagas = function* () {
	yield fork(uiWatchInitialize);
};
/** END UI * */


export default function* root() {
	try {
		yield all([
			fork(appSagas),
			fork(uiSagas),
			fork(authSagas),
			fork(userSagas),
			fork(playSagas),
		]);
	} catch (e) {
		logger.error('Sagas ERROR');
		logger.error(e);
	}
}
