import React from 'react';

import Ribbon from '../../../common/ribbon/RibbonComponent';

import './Footer.scss';
import {playGetPregames} from '../../play/store/selectors';
import PlayerStats from '../header/subcomponents/PlayerStatsComponent';

const FooterComponent = (props) => {
	return (
		<footer>
			{props.authIsAuthenticated && props.userInfo ? (
				<div className="stats-wrapper">
					<PlayerStats
						userInfo={props.userInfo}
						userCredits={props.userCredits}
					/>
				</div>
			) : (
				<div className="powered-by">
					{/*<p>powered by</p>*/}
					<img src={props.operatorLogo} alt=""/>
				</div>
			)
			}
		</footer>
	);
};

export default FooterComponent;
