import React, {Component} from 'react';
import {connect} from 'react-redux';
import PrizesComponent from './PrizesComponent';
import {appGetConfig} from '../../store/selectors';

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
});

const actionsToProps = dispatch => ({
});

@connect(stateToProps, actionsToProps)
class PrizesContainer extends Component {
	render() {
		return (
			<PrizesComponent prizes={this.props.appGetConfig.config.content.media} />
		);
	}
}

export default PrizesContainer;
