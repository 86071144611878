import React from 'react';
import getText, {HtmlParser} from '../../utils/language';
import {Link} from 'react-router-dom';

import Button from '../../common/button/ButtonComponent';

import './Intro.scss';

import logo from "../../resources/img/logo.png";
import shape5 from '../../resources/img/pattern-shapes/shape5.png';
import shape6 from '../../resources/img/pattern-shapes/shape6.png';
import shape7 from '../../resources/img/pattern-shapes/shape7.png';
import shape8 from '../../resources/img/pattern-shapes/shape8.png';

const IntroComponent = () => {
	return (
		<div className="intro-component">
			<img src={logo} alt="" className="logo"/>

			<div className="caption">
				<HtmlParser html="intro[text_caption]"/>
			</div>

			<Link to="/play" className="btn-wrapper">
				<Button
					fullWidth={true}
				>
					{getText('intro[button]')}
				</Button>
			</Link>

			<div className="loader-react">
				<div className="bottom-shapes">
					<img src={shape5} alt="Shape 5" className="shape-5"/>
					<img src={shape6} alt="Shape 6" className="shape-6"/>
					<img src={shape7} alt="Shape 7" className="shape-7"/>
					<img src={shape8} alt="Shape 8" className="shape-8"/>
				</div>
			</div>
		</div>
	);
};

export default IntroComponent;
