import React from 'react';
import clsx from 'clsx';

import './Knock.scss';

import trophy from '../../../../../resources/img/trophy-no-shadow.png';

const KnockComponent = (props) => {

	const renderCard = () => {
		return props.playGetPregame.prizes.map((card, index) => (
			<li key={index} className={clsx((index !== props.isFlipped && props.isFlipped) && "not-win")}>
				<div className="knock-card-wrapper">
					<div
						className={"knock-card" + (props.isFlipped === index ? " flipped" : "")}
						onClick={() => props.handleKnock(index)}
					>
						<div className="side front" style={{backgroundImage: `url(${props.playGetPregame.data.doors[index]})`}}></div>
						<div className="side back" style={{backgroundColor: "#FFFFFF"}}>
							<div className="prize">
								<img src={trophy} alt=""/>
								<p>{props.prize.texts[props.lang][0]}</p>
								<span>{props.prize.texts[props.lang][1]}</span>
							</div>
						</div>
					</div>
				</div>
			</li>
		))
	}

	return (
		<div className="knock-pregame">
			<div className="title">
				<h2>{props.playGetPregame.name[props.lang]}</h2>
				<p>{props.playGetPregame.subtitle[props.lang]}</p>
			</div>

			<div className="game-wrapper">
				<ul className="cards-list">
					{renderCard()}
				</ul>
			</div>
		</div>
	);
};

export default KnockComponent;
