import React from 'react';
import getText from 'AppUtils/language';
import {Link} from 'react-router-dom';

import Button from '../../../../common/button/ButtonComponent';

import './Restricted.scss';

const RestrictedComponent = (props) => {
	return (
		<div className="restricted-component">
			<div className="inputs-wrapper">
				<p>{getText('restricted[text_restricted]')}</p>
			</div>

			<Link to="/play" onClick={(e) => {
				e.preventDefault();
				props.userLoadInfo()}}>
				<Button
					fullWidth={true}
				>
					{getText('restricted[button_retry]')}
				</Button>
			</Link>
		</div>
	);
};

export default RestrictedComponent;
