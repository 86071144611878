import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userInfo} from '../user/store/selectors';
import {appGetConfig} from '../../store/selectors';

import LevelsComponent from './LevelsComponent';

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
});

@connect(stateToProps, actionsToProps)
class LevelsContainer extends Component {
	render() {
		return (
			<LevelsComponent
				user={this.props.userInfo}
				levels={this.props.appGetConfig.levels}
			/>
		);
	}
}

export default LevelsContainer;
