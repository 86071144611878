import React from "react";
import {Link} from 'react-router-dom';

import Menu from "../../menu/MenuContainer";
import Ribbon from '../../../../common/ribbon/RibbonComponent';

import "./Header.scss";

import iconNav from "./img/icon-nav.svg";
import iconBack from "./img/icon-arrow-back.png";
import iconClose from "./img/icon-close.png";
import logo from './img/logo.png';

const HeaderComponent = (props) => {
	return (
		<header>
			{props.menuIsOpen && <div className="menu-bg-overlay" onClick={() => props.toggleMenu()}></div>}
			<Menu menuIsOpen={props.menuIsOpen} toggleMenu={props.toggleMenu} authIsAuthenticated={props.authIsAuthenticated} authStatus={props.authStatus} authLogout={props.authLogout} />

			<div className="nav-control">
				{props.location === "/edit-profile" ? (

					<div className="btn-nav">
						<Link to="/profile">
							<img src={iconClose} alt="Go back" />
						</Link>
					</div>

				) : (

					<div className="btn-nav">
						<img
							src={props.menuIsOpen ? iconNav : iconNav}
							alt="Open nav"
							onClick={() => props.toggleMenu()}
						/>
					</div>

				)}
				<img className="logo-header" src={logo} alt=""/>

				{props.authIsAuthenticated && props.prize?.texts && !props.pathname.includes("/play-pregame") ?
					<div className="play-for">
						<Ribbon
							className="banner-small"
							fill={props.prize?.background_color || "rgb(230, 50, 49)"}
						/>
						<p className="banner-content">
							{/*<span className="banner-title">PLAY FOR</span>*/}
							<strong className="banner-points">{props.prize?.texts[props.lang][0]}</strong>
							<span className="banner-points-text">{props.prize?.texts[props.lang][1]}</span>
						</p>
					</div>
					: null
				}
			</div>
		</header>
	);
};

export default HeaderComponent;
