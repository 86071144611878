import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import clsx from 'clsx';
import getText from 'AppUtils/language';

import './GamesSlider.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GamesSliderComponent = (props) => {

	const settingsSliderMain = {
		className: "slider-main",
		dots: false,
		arrows: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: "42px",
		asNavFor: props.nav2,
	};

	const settingsSliderNav = {
		className: "slider-nav",
		dots: false,
		arrows: false,
		infinite: false,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		variableWidth: true,
		focusOnSelect: true,
		asNavFor: props.nav1,
	}

	const renderGames = () => {
		return props.games.map(game => (
			<div
				className="item"
				key={game.id}
			>
				<Link
					to={props.virtualGames ? game.data.launchUrl : `/play/${game.id}`}
					style={{
						backgroundImage: `url(${game.image})`
					}}
					onClick={(e) => {
						if (props.userInfoGames[game.id] === 'locked' || game.min_level < props.userInfo?.level) {
							e.preventDefault();
						} else {
							props.handlePlayGame(e, game.id, game.data.launchUrl)
						}
					}}
				>
					<p className={clsx({
						"btn-play": true,
						locked: (props.userInfoGames[game.id] === 'locked' || game.min_level < props.userInfo?.level) && !props.virtualGames,
						completed: props.userInfoGames[game.id] === 'completed' && !props.virtualGames,
						inverted: !props.pregames
					})}>
						<span>
							{props.userInfoGames[game.id] === 'playable' || props.virtualGames ?
								getText('games[text_play]')
								:
								props.userInfoGames[game.id] === 'completed' ?
									getText('games[text_completed]')
									:
									props.userInfoGames[game.id] === 'locked' ?
										getText('games[text_locked]')
										:
										getText('games[text_play]')
							}
						</span>
					</p>
				</Link>
			</div>
		))
	};

	const renderNav = () => {
		return props.games.map(game => (
			<div
				className="item-nav"
				key={game.id}
			>
				<p className="item-nav-title">{game.name[props.lang]}</p>
			</div>
		))
	}

	return (
		<div className="games-slider">
			<Slider{...settingsSliderNav} ref={props.setSlider2}>
				{renderNav()}
			</Slider>
			<Slider {...settingsSliderMain} ref={props.setSlider1}>
				{renderGames()}
			</Slider>
		</div>
	);
};

export default GamesSliderComponent;
