import { createSelector } from 'reselect';

const userMsg = state => state.user.msg;
const userStatusCode = state => state.user.statusCode;
const userInfo = state => state.user.info;
const userUpdateInfo = state => state.user.updateInfo;
const userPoints = state => state.user.points;
const userCredits = state => state.user.credits;
const userLeaderBoard = state => state.user.leaderBoard;
const userEvent = state => state.user.event;
const userUnsubscribe = state => state.user.unsubscribe;
const userTransactions = state => state.user.transactions;

export {
	userMsg,
	userStatusCode,
	userInfo,
	userUpdateInfo,
	userPoints,
	userCredits,
	userLeaderBoard,
	userEvent,
	userUnsubscribe,
	userTransactions,
};
