import {
    put, fork, race, take, call, takeLatest, cancel, cancelled, select, delay
} from 'redux-saga/effects';
import Observable from 'Observable';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import ls from 'local-storage';
import { createBrowserHistory } from 'history';

import { getQueryVariables, removeQueryVariable, getQueryVariable } from 'AppUtils/url';
import { getValue } from 'AppUtils/objects';
import XDate from 'AppUtils/xdate';

import * as AUTH from './types';
import * as USER from '../../user/store/types';
import * as PLAY from '../../play/store/types';
import * as COMMON from '../../../store/types';
import { apiPost, apiGet, apiPatch } from 'AppUtils/api';
import { authUuid } from "./selectors";
import { uiGetSection } from "../../../store/selectors";


let apikey;
function* onSet(action) {
    const newApikey = action.payload.apikey;

    if (newApikey) {
		apikey = newApikey;
        ls.set('apikey', apikey);
    }
}

function* onCheck() {
    const tokenLogin = yield call(checkTokenLogin);
	const section = yield select(uiGetSection);
	let isAuthenticated = false;
	let statusCode = '';

	const history = createBrowserHistory();

    if (tokenLogin) {
		const tokenPayload = yield take(USER.USER_SET_LOGIN_WITH_TOKEN);
		const { payload: { apikey = '', msg = '' } } = tokenPayload;
		statusCode = tokenPayload.payload.statusCode;

		if (apikey) {
			yield postQuickLogin();
			yield put({
				type: AUTH.AUTH_SET,
				payload: { apikey, msg, statusCode },
			});
			isAuthenticated = true;
			//remove token query from URL
		}

		yield history.push(window.location.pathname);
    }

	const apikey = yield call(getApikey);
	const uuid = yield select(authUuid);
	let info = '';
	//let expires = '';
	let eligible = '';
	let credits = '';
	let msg = '';
	let scheme = '';

	if (apikey && uuid) {
		// Make a request and get my user info
		const response = yield apiGet(`/user/info?uuid=${uuid}`).catch((e) => {
				return Observable.empty();
			}).mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && response.settings) {
				info = response;
				//expires = getValue(response, 'eligibility.expires');
				eligible = getValue(response, 'eligibility.eligible');
				scheme = getValue(response, 'eligibility.scheme');
				credits = getValue(response, 'eligibility.credits');
			} else {
				msg = 'Error';
			}
		});

		if (!msg) {
			//user valid
			if ((scheme == 'subscription' && eligible)
			|| (scheme == 'credits' && eligible && (credits > 0 || info.session.open))) {
				yield put({
					type: AUTH.AUTH_SET,
					payload: { apikey, status: 'valid', statusCode },
				});

				isAuthenticated = true;
			} else {
				yield put({
					type: AUTH.AUTH_SET,
					payload: { apikey, status: 'restricted', statusCode },
				});

				isAuthenticated = false;
			}

			yield put({
				type: USER.USER_SET_INFO,
				payload: { info, statusCode },
			});
		} else {
			yield put({ type: AUTH.AUTH_RESET });
			isAuthenticated = false;
		}
	} else {
		yield put({ type: AUTH.AUTH_RESET });
		isAuthenticated = false;
	}
}

function* checkTokenLogin() {
	const token = getQueryVariable(window.location.search, process.env.REACT_APP_TOKEN_QUERY, '');
    if (token) {
        // Make a request and get my user info
        yield put({ type: USER.USER_LOAD_LOGIN_WITH_TOKEN, payload: { token } });
        return true;
    }
}

function* postQuickLogin() {

}

function* onLogout() {
	ls.set('apikey', '');
	yield put({ type: USER.USER_SET_INFO, payload: { info: {}  } });
    yield put({ type: AUTH.AUTH_UPDATE, payload: { apikey: null } });
    yield put({ type: AUTH.AUTH_RESET });
}


function* authUpdater() {
    const checker = function* () {
        try {
            const ttl = 1000 * 60 * process.env.REACT_APP_WEBAPP_DATA_REFRESH;// Check the auth each x minutes
			let statusCode = '';
            while (true) {
                const apikey = yield call(getApikey);
                const uuid = yield select(authUuid);
                if (!apikey || !uuid) {
                    yield put({ type: AUTH.AUTH_RESET });
                    break;
                }

				const response = yield apiGet(`/user/status?uuid=${uuid}`).catch((e) => {
					return Observable.empty();
				}).mergeMap(res => {
					const resp = res.json();
					statusCode = res.status;
					return resp;
				}).toPromise().then(function (response) {
					if (response && response.expires) {
						// const user = response.params;
						//
						// yield put({
						// 	type: AUTH.AUTH_UPDATE,
						// 	payload: {
						// 		token: (user.session_key ? user.session_key : token),
						// 		uuid,
						// 		user,
						// 	},
						// });
					}
				});

				yield delay(ttl);
            }
            yield cancel(proc);
        } finally {
            if (yield cancelled()) {}
        }
    };

    const proc = yield fork(checker);
    if (yield take(AUTH.AUTH_RESET)) {
        yield cancel(proc);
    }
}


function getApikey() {
    try {
        return ls.get('apikey');
    } catch (e) {
        return '';
    }
}

function* initRestrictions() {
    // Watch for auth/signin and then check the routes
    let user = null;
    let pathname = window.location.pathname;
    let hash = window.location.hash;
    while (true) {
        // Wait for the auth_check to issue set or reset
        const result = yield race({
            set: take(AUTH.AUTH_SET),
            reset: take(AUTH.AUTH_RESET),
            location: take(LOCATION_CHANGE),
        });

        if (result.location) {
            pathname = getValue(result.location, 'payload.pathname', 'unknown');
            hash = getValue(result.location, 'payload.hash', 'unknown');
        } else if (result.set) {
            user = result.set.payload.user;
        } else if (result.reset) {
            user = null;
        }
    }
}


function* accountUpdater() {
    while (true) {
        // Wait for the auth_check to issue set or reset
        const { set, reset } = yield race({
            set: take(AUTH.AUTH_SET),
            reset: take(AUTH.AUTH_RESET),
        });
    }
}


function* watchInitialize() {
    yield takeLatest(AUTH.AUTH_SET, onSet);
    //yield takeLatest(AUTH.AUTH_SET, authUpdater);
    yield takeLatest(AUTH.AUTH_CHECK, onCheck);
    yield takeLatest(AUTH.AUTH_LOGOUT, onLogout);
}

export default function* sagas() {
    yield fork(initRestrictions);
    yield fork(accountUpdater);
    yield fork(watchInitialize);
}
