import React, { Component } from 'react';
import { connect } from 'react-redux';

import GameDetails from './GameDetailsComponent';
import Loader from '../../../../common/loader/Loader';

import { uiRedirect } from '../../../../store/actions';
import { playGetGame, playStartGame } from "../../store/selectors";
import { appGetInterfaceLang } from "../../../../store/selectors";
import {playLoadStartGame} from '../../store/actions';
import {userLoadEvent} from '../../../user/store/actions';

const stateToProps = (state, props) => {
	const gameId = props.match.params.id;
	const playGame = playGetGame(state, gameId);

	return (state) => ({
		playGetGame: playGame(state),
		playStartGame: playStartGame(state),
		lang: appGetInterfaceLang(state),
	});
};

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	playLoadStartGame: (payload) => dispatch(playLoadStartGame(payload)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
});

@connect(stateToProps, actionsToProps)
class GameDetailsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			gameId: this.checkGame(props.match.params.id)
		}
	}

	checkGame = (url) => {
		const urlParam = parseInt(this.props.match.params.id);

		return (urlParam >= 1 && urlParam <= 6) ? urlParam : '';
	}

	componentDidMount() {
		const gameId = this.props.match.params.id;

		if (!this.props.location.state || this.props.location.state === undefined || !this.props.location.state.runGame || !this.props.playGetGame) {
			this.props.uiRedirect({
				pathname: '/'
			})
		} else {
			this.props.playLoadStartGame({id: this.props.playGetGame.id})
			this.props.userLoadEvent({ type: 'ACTIVITY_START', metadata: `{"activity_session_id": ${Math.floor(Math.random() * 1000)}, "activity_type": 2, "activity_subtype": ${gameId}}`})

			if (this.props.userInfo.games[this.props.playGetGame.id] !== 'playable' && this.props.userInfo.games[this.props.playGetGame.id] !== 'playing') {
				this.props.uiRedirect({
					pathname: '/'
				})
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const currentGame = this.props.playGetGame;

		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.setState({
				gameId: this.checkGame(this.props.match.params.id)
			})
		}
	}

	render() {
		if(!this.props.playStartGame) {
			return <Loader/>;
		}

		return (
			<GameDetails
				{...this.state}
				playGetGame={this.props.playGetGame}
				playStartGame={this.props.playStartGame}
				lang={this.props.lang}
				uiRedirect={this.props.uiRedirect}
			/>
		);
	}
}

export default GameDetailsContainer;
