import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { getValue } from 'AppUtils/objects';
import getText from 'AppUtils/language';

import {
	authIsAuthenticated, authStatus
} from './store/selectors';
import { uiRedirect } from 'AppStore/actions';
import { userInfo } from '../user/store/selectors';

const stateToProps = state => ({
	authIsAuthenticated: authIsAuthenticated(state),
	authStatus: authStatus(state),
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class CheckAuth extends React.Component {

	render() {
		if(this.props.authIsAuthenticated && this.props.authStatus) {
			if(!this.props.initial) {
				if(this.props.userInfo && this.props.userInfo.settings) {
					if(typeof this.props.userInfo.settings.nickname === 'undefined') {
						this.props.uiRedirect({
							pathname: '/edit-profile'
						})
						return null;
					} else {
						let onboardingStorage = JSON.parse(localStorage.getItem('onboardingVsa'));
						let onboardingSettings = this.props.userInfo.settings.onboardingSeen;

						if(!onboardingSettings && !onboardingStorage) {
							this.props.uiRedirect({
								pathname: '/about'
							})
							return null;
						}
					}
				}

				if(this.props.authStatus === 'restricted') {
					if(!(this.props.location && this.props.location.pathname === '/restricted')) {
						this.props.uiRedirect({
							pathname: getValue(this.props, 'userInfo.eligibility.scheme') == 'credits' ? '/about' : '/restricted'
						})
						return null;
					}
				} else {
					if(this.props.restrictedOnly && this.props.authStatus === 'valid') {
						this.props.uiRedirect({
							pathname: '/'
						})
						return null;
					}
				}

				if(!this.props.restricted) {
					this.props.uiRedirect({
						pathname: '/play'
					})

					return null;
				}
			}
		} else {
			if(this.props.restricted) {
				this.props.uiRedirect({
					pathname: '/signin'
				})

				return null;
			}
		}

		// if(this.props.authIsAuthenticated && !this.props.restricted && this.props.authStatus === 'valid') {
		// 	//redirect play
		// 	let onboarding = JSON.parse(localStorage.getItem('onboardingVsa'));
		// 	// if(this.props.userInfo.settings.onboardingSeen) {
		// 	// 	onboarding = onboarding;
		// 	// }
		// 	if(!onboarding) {
		// 		this.props.uiRedirect({
		// 			pathname: '/about'
		// 		})
		// 	} else {
		// 		this.props.uiRedirect({
		// 			pathname: '/play'
		// 		})
		// 	}
		// } else if(this.props.authIsAuthenticated && this.props.restricted && this.props.authStatus === 'valid') {
		// 	let onboarding = JSON.parse(localStorage.getItem('onboardingVsa'));
		// 	if(!onboarding) {
		// 		this.props.uiRedirect({
		// 			pathname: '/about'
		// 		})
		// 	}
		// } else if(!this.props.authIsAuthenticated && this.props.restricted) {
		// 	//redirect login
		// 	this.props.uiRedirect({
		// 		pathname: '/'
		// 	})
		//
		// 	return null;
		// } else if(this.props.authIsAuthenticated && this.props.restricted && this.props.authStatus === 'restricted') {
		// 	this.props.uiRedirect({
		// 		pathname: '/restricted'
		// 	})
		// } else if(this.props.authStatus === 'restricted' && this.props.authOnly) {
		// 	this.props.uiRedirect({
		// 		pathname: '/restricted'
		// 	})
		// } else if(!this.props.authIsAuthenticated && this.props.loggedInOnly) {
		// 	console.log('loged in')
		// 	this.props.uiRedirect({
		// 		pathname: '/'
		// 	})
		// }

		return React.cloneElement(this.props.children, { userInfo: this.props.userInfo ? this.props.userInfo : '' });
	}
}

export default CheckAuth;
