import React from 'react';
import getText from '../../utils/language';

import LevelItem from '../level-item/LevelItem';

import './LevelsGrid.scss';

import IconNewbie from '../../resources/img/icons-level/icon-newbie.png';
import IconSkilled from '../../resources/img/icons-level/icon-skilled.png';
import IconPro from '../../resources/img/icons-level/icon-pro.png';
import IconGuru from '../../resources/img/icons-level/icon-guru.png';

const LevelsGrid = ({currentLevel, levels, lang}) => {
	const levelCheck = (level) => {
		if(typeof currentLevel === 'undefined') {
			return 'active';
		}
		return currentLevel > level ? 'completed' : currentLevel === level ? 'active' : 'inactive';
	}

	const renderLevels = () => {
		return levels.map((level, index) => {
			return (
				<LevelItem
					icon={level.badge}
					title={level.name[lang]}
					status={levelCheck(index + 1)}
				/>
			);
		});
	}

	return (
		<div className="levels-grid">
			{renderLevels()}
		</div>
	);
};

export default LevelsGrid;
