import React from 'react';

import './FillShape.scss';
import iconTimer from '../../main/img/icon-timer.png';
import Timer from 'react-compound-timer';
import clsx from 'clsx';

const FillShapeComponent = (props) => {

	const renderAnswers = () => {
		return props.answers.map((answer) => {
			return (
				<div
					className={clsx("answer", props.choice?.image === answer.image && (answer.correct ? "correct" : "incorrect"))}
					key={answer.id}
					onClick={() => props.setChoice(answer)}
				>
					<img src={answer.image} alt=""/>
					<div className={clsx("answer-border")}></div>
				</div>
			)
		})
	}

	return (
		<div className="fill-shape-game">
			<div className="title">
				<h2>{props.playGetGame.name[props.lang]}</h2>
				<p>{props.playGetGame.subtitle[props.lang]}</p>
			</div>
			<div className="game-timer">
				<img src={iconTimer} alt=""/>
				<div className="time-left"><Timer.Minutes
					formatValue={value => ("0" + value).slice(-2)}/>:<Timer.Seconds
					formatValue={value => ("0" + value).slice(-2)}/></div>
			</div>

			<div className="game-wrapper">
				<div className="shapes-image">
					<img src={props.playGetGame.data.main} alt=""/>
				</div>
				<div className="answers-list">
					{renderAnswers()}
				</div>
			</div>
		</div>
	);
};

export default FillShapeComponent;
