import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getValue} from '../../../../utils/objects';

import { uiRedirect } from '../../../../store/actions';
import {playGetPickPregame, playGetPregame} from "../../store/selectors";
import { appGetInterfaceLang } from "../../../../store/selectors";
import {playLoadPickPregame, playLoadPregames} from '../../store/actions';
import {userLoadEvent, userLoadInfo} from '../../../user/store/actions';

import PregameDetailsComponent from './PregameDetailsComponent';
import isEqual from 'lodash-es/isEqual';
import Loader from '../../../../common/loader/Loader';

const stateToProps = (state, props) => {
	const gameId = props.match.params.id;
	const playPregame = playGetPregame(state, gameId);

	return (state) => ({
		playGetPickPregame: playGetPickPregame(state),
		playGetPregame: playPregame(state),
		lang: appGetInterfaceLang(state),
	});
};

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	playLoadPregames: (payload) => dispatch(playLoadPregames(payload)),
	playLoadPickPregame: (payload) => dispatch(playLoadPickPregame(payload)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
	userLoadInfo: (data) => dispatch(userLoadInfo(data)),
});

@connect(stateToProps, actionsToProps)
class PregameDetailsContainer extends Component {

	constructor(props) {
		super(props);

		const extraLife = props.location?.state?.extraLife;

		this.state = {
			gameId: parseInt(props.match.params.id),
			prizeId: getValue(props.userInfo, 'session.prizeId'),
			extraLife
		}

		if(getValue(props.userInfo, 'session.pregame.played') && !extraLife) {
			props.uiRedirect({
				pathname: '/'
			})
		}

		if(getValue(props.userInfo, 'session.pregame') && !getValue(props.userInfo, 'session.pregame.played')) {
			props.uiRedirect({
				pathname: `/play-pregame/${getValue(props.userInfo, 'session.pregame.id')}`
			})
		}
	}

	componentDidMount() {
		const gameId = this.props.match.params.id;

		if(!this.props.playGetPregame) {
			this.props.playLoadPregames();
		}

		this.props.playLoadPickPregame({id: gameId, extraLife: this.state.extraLife})
		this.props.userLoadEvent({ type: 'ACTIVITY_START', metadata: `{"activity_session_id": ${Math.floor(Math.random() * 1000)}, "activity_type": 2, "activity_subtype": ${gameId}}`})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.setState({
				gameId: parseInt(this.props.match.params.id)
			})
		}

		if(!isEqual(prevProps.playGetPickPregame, this.props.playGetPickPregame) && this.props.playGetPickPregame) {
			this.props.userLoadInfo()
			this.setState({
				prizeId: this.props.playGetPickPregame.prizeId
			})
		}
	}

	render() {
		if(!this.props.playGetPregame || !this.state.prizeId) {
			return <Loader />;
		}

		return (
			<PregameDetailsComponent
				{...this.state}
				playGetPregame={this.props.playGetPregame}
				playGetPickPregame={this.state.prizeId}
				lang={this.props.lang}
				uiRedirect={this.props.uiRedirect}
			/>
		);
	}
}

export default PregameDetailsContainer;
