import React, {Component} from 'react';
import IntroComponent from './IntroComponent';

class IntroContainer extends Component {
	render() {
		return (
			<IntroComponent />
		);
	}
}

export default IntroContainer;
