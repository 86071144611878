import React, {Component} from 'react';
import {userInfo} from '../user/store/selectors';
import {appLoadTranslations, uiRedirect, uiToggleModal} from '../../store/actions';
import {userLoadUnsubscribe} from '../user/store/actions';
import {connect} from 'react-redux';
import {authLogout} from '../auth/store/actions';
import getText from '../../utils/language';

import ProfileComponent from './ProfileComponent';

const stateToProps = state => ({
	userInfo: userInfo(state),
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	appLoadTranslations: (data) => dispatch(appLoadTranslations(data)),
	authLogout: () => dispatch(authLogout()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	userLoadUnsubscribe: () => dispatch(userLoadUnsubscribe()),
});

@connect(stateToProps, actionsToProps)
class ProfileContainer extends Component {

	logout = () => {
		this.props.uiRedirect({
			pathname: '/'
		})
		this.props.authLogout();
	}

	unsubscribe = () => {
//		const authenticationNotManual = this.props.userInfo.eligibility.authentication && this.props.userInfo.eligibility.authentication != 'manual'

//		if (authenticationNotManual) {
//			this.openGeneralModal('', getText('unsubscribe[text_confirm]'), true, getText('unsubscribe[button_yes]'),() => {
//				this.props.userLoadUnsubscribe();
//				this.closeGeneralModal();
//			});
//		} else {
			this.props.uiRedirect({
				pathname: '/unsubscribe'
			});
//		}
	}

	openGeneralModal = (title, message, button, buttonText, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, callback  } );
	};

	closeGeneralModal = () => {
		this.props.uiToggleModal({}, { toggle: false });
	}


	render() {

		return (
			<ProfileComponent
				logout={this.logout}
				unsubscribe={this.unsubscribe}
				userInfo={this.props.userInfo}
			/>
		);
	}
}

export default ProfileContainer;
