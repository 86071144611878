import React from 'react';
import getText from '../../utils/language';
import {Link} from 'react-router-dom';

import Button from '../../common/button/ButtonComponent';

import './Profile.scss';

import avatarPlaceholder from "./img/avatar-placeholder.png";
import iconNewbie from '../../resources/img/icons-level/icon-newbie.png';
import iconPro from '../../resources/img/icons-level/icon-pro.png';
import iconSkilled from '../../resources/img/icons-level/icon-skilled.png';
import iconGuru from '../../resources/img/icons-level/icon-guru.png';
import clsx from 'clsx';

const ProfileComponent = (props) => {
	let iconLevel = '';
	let textLevel = '';

	switch(props.userInfo.level) {
		case 1:
			iconLevel = iconNewbie;
			textLevel = getText('global[newbie]');
			break;
		case 2:
			iconLevel = iconPro;
			textLevel = getText('global[pro]');
			break;
		case 3:
			iconLevel = iconSkilled;
			textLevel = getText('global[skilled]');
			break;
		case 4:
			iconLevel = iconGuru;
			textLevel = getText('global[guru]');
			break;
	}

	const subscribed = props.userInfo.eligibility.subscribed;
	const eligible = props.userInfo.eligibility.eligible;

	return (
		<div className="profile-component">
			<div className="title">
				<h2>{getText("profile[text_title]")}</h2>
				<p>{getText("profile[text_subtitle]").replace("$levelText", textLevel)}</p>
			</div>

			<div className="user-avatar">
				<img src={props.userInfo.settings.avatar ? props.userInfo.settings.avatar : avatarPlaceholder} alt=""/>
				<div className="user-level">
					<img src={iconLevel} alt=""/>
				</div>
			</div>

			<div className="user-status">
				<p className={clsx(subscribed && "active")}>{subscribed ? getText("profile[text_subscribed]") : getText("profile[text_not_subscribed]")}</p>
				<p className={clsx(eligible && "active")}>{eligible ? getText("profile[text_eligible]") : getText("profile[text_not_eligible]")}</p>
			</div>

			<div className="btn-group">
				<Link to="/edit-profile">
					<Button
						fullWidth={true}

					>
						{getText("profile[button_edit]")}
					</Button>
				</Link>
				<Button
					fullWidth={true}
					inverted={true}
					onClick={() => props.logout()}
				>
					{getText("profile[button_logout]")}
				</Button>
				<Button
					fullWidth={true}
					inverted={true}
					onClick={() => props.unsubscribe()}
				>
					{getText("profile[button_unsubscribe]")}
				</Button>
			</div>
		</div>
	);
};

export default ProfileComponent;
