import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Observable from 'Observable';
import getText, { HtmlParser } from 'AppUtils/language';
import { apiPost } from 'AppUtils/api';
import Modal from 'react-modal';
import ReactGA from 'react-ga4';

import Layout from './components/layout/Layout';
import Routes from './routes';
import Button from './common/button/ButtonComponent';

import './App.scss';
import './resources/css/align-right.scss';

import logo from "./resources/img/logo.png";
import iconClose from './resources/img/icon-close.png';
import shape1 from './resources/img/pattern-shapes/shape1.png';
import shape2 from './resources/img/pattern-shapes/shape2.png';
import shape3 from './resources/img/pattern-shapes/shape3.png';
import shape4 from './resources/img/pattern-shapes/shape4.png';
import shape5 from './resources/img/pattern-shapes/shape5.png';
import shape6 from './resources/img/pattern-shapes/shape6.png';
import shape7 from './resources/img/pattern-shapes/shape7.png';
import shape8 from './resources/img/pattern-shapes/shape8.png';
import africanShapes from './resources/img/african-shapes.png';

import {
	appGetStatus,
	appGetInterfaceLang,
	uiGetModal,
	appGetConfig
} from 'AppStore/selectors';

import { appInitialize, uiToggleModal, uiRedirect } from 'AppStore/actions';
import { uiGetSection } from "./store/selectors";
import { authStatus, authUuid } from './components/auth/store/selectors';
import {userInfo, userLeaderBoard, userStatusCode, userUnsubscribe} from './components/user/store/selectors';
import { authSet } from "./components/auth/store/actions";
import { userLoadInfo, userLoadLeaderBoard } from "./components/user/store/actions";
import { appLoadSessionArchive, appLoadTranslations } from "./store/actions";
import clsx from 'clsx';

Modal.setAppElement('#root')

const stateToProps = state => ({
	appStatus: appGetStatus(state),
	appGetInterfaceLang: appGetInterfaceLang(state),
	appGetStatus: appGetStatus(state),
	uiGetModal: uiGetModal(state),
	uiGetSection: uiGetSection(state),
	authStatus: authStatus(state),
	authUuid: authUuid(state),
	appGetConfig: appGetConfig(state),
	userInfo: userInfo(state),
	userUnsubscribe: userUnsubscribe(state),
	userStatusCode: userStatusCode(state),
});

const actionsToProps = dispatch => ({
	appInitialize: () => dispatch(appInitialize()),
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	authSet: (auth) => dispatch(authSet(auth)),
	userLoadInfo: (data) => dispatch(userLoadInfo(data)),
	appLoadSessionArchive: () => dispatch(appLoadSessionArchive()),
	appLoadTranslations: (data) => dispatch(appLoadTranslations(data)),
	userLoadLeaderBoard: (data) => dispatch(userLoadLeaderBoard(data)),
});

@connect(stateToProps, actionsToProps)
class App extends React.Component {

	state = {
		loading: true,
		total: 5,
		current: 1,
		onLine: window.navigator.onLine
	};

	openModal = (title, message, button, buttonText, restrictClose, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, callback } );
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	handleOffline = () => {
		if(this.props.appStatus !== 'error') {
			this.openModal(getText('global[text_error]'), 'No internet connection detected. Please try again.', true, getText('restricted[button_retry]'), true, () => {
				if(window.navigator.onLine) {
					// this.props.appInitialize();
					this.props.uiToggleModal({}, { toggle: false }, {} );
					this.props.uiRedirect({
						pathname: '/'
					})
				}
			});
		}
	}

	handleServerError = () => {
		this.openModal('Error', 'There seems to be a problem. Please try again.', true, 'Retry', true, () => {
			this.props.appInitialize();
		});
	}

	componentDidMount() {
		const bodySelector = document.getElementsByTagName("body")[0];
		bodySelector.classList.add("app-ready");
		this.props.appInitialize();
		this.props.appLoadSessionArchive();

		ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
		window.isReactGAInitialized = true;
		// window.addEventListener('online', () => this.handleOnline());
		window.addEventListener('offline', () => this.handleOffline());

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.appStatus !== this.props.appStatus) {
			if(this.props.appStatus === 'ready') {
				setTimeout(() => this.setState({ loading: false }), 1000);
			} else if(this.props.appStatus === 'error') {
				this.handleServerError();
			}
		}

		if(prevProps.userUnsubscribe !== this.props.userUnsubscribe) {
			if(this.props.userUnsubscribe === 'done') {
				this.openModal(getText('global[text_success]'), getText('unsubscribe[text_success]'), true, getText('global[button_ok]'), true, () => {
					this.props.uiToggleModal({}, { toggle: false }, {} );
					this.props.uiRedirect({
						pathname: '/'
					})
				});
			} else if (this.props.userUnsubscribe === 'error') {
				this.props.uiToggleModal({}, { toggle: false }, {} );
				let errorMsg = getText('unsubscribe[text_error]');
				if(this.props.userStatusCode === 412) {
					errorMsg = getText('unsubscribe[text_no_subscription]');
				}
				this.openModal(getText('global[text_error]'), errorMsg, true, getText('global[button_ok]'), true, () => {
					this.props.uiToggleModal({}, { toggle: false }, {} );
				});
			}
		}

		if(prevProps.userInfo !== this.props.userInfo) {
			localStorage.setItem('onboardingVsa', false);
			if(this.props.userInfo.settings && this.props.userInfo.settings.onboardingSeen) {
				localStorage.setItem('onboardingVsa', this.props.userInfo.settings.onboardingSeen);
			}
		}

		if(prevProps.appGetInterfaceLang !== this.props.appGetInterfaceLang) {
			document.documentElement.lang = this.props.appGetInterfaceLang;
		}
	}

	renderGeneralModal = () => (
		<Modal
			isOpen={this.props.uiGetModal.options.toggle}
			onRequestClose={this.props.uiGetModal.content && this.props.uiGetModal.content.restrictClose ? false : this.closeModal}
			contentLabel="Example Modal"
			className={clsx("modal-general", !this.props.uiGetModal?.content?.restrictClose && "has-close", this.props.uiGetModal?.content?.extraClass && this.props.uiGetModal.content.extraClass)}
		>
			<div className="modal-content">
				{this.props.uiGetModal.content && this.props.uiGetModal.content.restrictClose ? null : <button className="close-modal" onClick={this.closeModal}><img src={iconClose} alt=""/></button>}
				{this.props.uiGetModal?.content?.icon ? <img src={this.props.uiGetModal.content.icon} alt="" className="modal-icon"/> : null}
				<h3 className="modal-title">{this.props.uiGetModal.content ? this.props.uiGetModal.content.title : ''}</h3>
				<p className="modal-message">{this.props.uiGetModal.content ? this.props.uiGetModal.content.message : ''}</p>

				{this.props.uiGetModal.content && this.props.uiGetModal.content.button ?
					<Button
						inverted={true}
						paddingSides={20}
						onClick={this.props.uiGetModal.content.callback && this.props.uiGetModal.content.callback}
					>{this.props.uiGetModal.content.buttonText}</Button>
					:
					null
				}
			</div>
			<img src={africanShapes} alt="" className="modal-bottom-shapes"/>
		</Modal>
	)

	render () {
		const { loading } = this.state;

		if(loading) {
			return (
				<>
					<div className="loader-react">
						<div className="top-shapes">
							<img src={shape1} alt="Shape 1" className="shape-1"/>
							<img src={shape2} alt="Shape 2" className="shape-2"/>
							<img src={shape3} alt="Shape 3" className="shape-3"/>
							<img src={shape4} alt="Shape 4" className="shape-4"/>
						</div>
						<img className="loader-logo" src={logo} alt="Logo" />
						<div className="bottom-shapes">
							<img src={shape5} alt="Shape 5" className="shape-5"/>
							<img src={shape6} alt="Shape 6" className="shape-6"/>
							<img src={shape7} alt="Shape 7" className="shape-7"/>
							<img src={shape8} alt="Shape 8" className="shape-8"/>
						</div>
					</div>
					{this.renderGeneralModal()}
				</>
			)
		}

		const renderRoutes = () => {
			if(this.props.uiGetSection === 'index') {
				return <Routes  />;
			} else {
				return <Layout hideFooter={this.props.uiGetSection === 'about'}><Routes /></Layout>;
			}
		}

		return (
			<div key={this.props.appGetInterfaceLang} className={`app ${this.props.uiGetSection} ${this.props.appGetInterfaceLang}`}>
				{renderRoutes()}

				{this.renderGeneralModal()}
			</div>
		);
	}
}

export default App;
