import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import getText from 'AppUtils/language';


import iconClose from '../header/main/img/icon-close.png';
import menuNotice from '../menu/img/menu-notice.png';

import './Menu.scss';
import './ModalLanguage.scss';

Modal.setAppElement('#root')

const MenuComponent = (props) => {

	const renderLanguages = () => {
		if(props.appGetConfig && props.appGetConfig.config && props.appGetConfig.config.i18n) {
			return Object.keys(props.appGetConfig.config.i18n.languages).map((language, i) => (
				<div className="custom-radio" key={language}>
					<input
						type="radio"
						id={language}
						name="radio-group"
						value={language}
						checked={props.currentLanguage === language}
						onChange={(e) => props.handleChangeLanguage(e)}
					/>
					<label htmlFor={language}>({language}) {props.appGetConfig.config.i18n.languages[language]}</label>
				</div>
			))
		}
	};

	const renderMenuItems = () => {
		let menuItems = {
			primary: [],
			secondary: []
		};
		props.menuItems.map((item, i) => {
			if((item.link === '/logout' || item.link === '/unsubscribe') && props.authStatus === 'guest') {

			} else {
				menuItems[item.secondary ? "secondary" : "primary"].push(
					<li className={(item.restricted && props.authStatus === 'restricted' && item.link !== '/logout' && item.link !== '/profile' && item.link !== '/prizes') || ((item.restricted || item.restrictedLoggedOut) && !props.authIsAuthenticated) ? 'restricted' : ''} key={i}>
						<Link target={item.externalLink ? '_blank' : '_self'} to={{pathname: item.externalLink ?? item.link}} onClick={item.callback ? (e) => {
								e.preventDefault();
								props.toggleMenu();
								setTimeout(() =>props.openModal(), 300);
							}
							: item.restricted && !props.authIsAuthenticated ? (e) => {
									e.preventDefault();
								}
								: item.link === '/logout' ? (e) => {
										e.preventDefault();
										props.uiRedirect({
											pathname: '/'
										})
										props.authLogout();
										props.toggleMenu();
									}
									: item.link === '/unsubscribe' && !item.externalLink ? (e) => {
											e.preventDefault();
											props.toggleMenu();

//											if (props.authenticationNotManual) {
//												props.openGeneralModal('', getText('unsubscribe[text_confirm]'), true, getText('unsubscribe[button_yes]'),() => {
//													props.userLoadUnsubscribe();
//												});
//											} else {
												props.uiRedirect({
													pathname: '/unsubscribe'
												})
//											}
										}
										: () => {
											props.toggleMenu();
										}}>
							{item.text}
						</Link>
					</li>
				)
			}
		})

		return menuItems;
	}

	return (
		<>
			<Modal
				isOpen={props.modalIsOpen}
				onRequestClose={props.closeModal}
				contentLabel="Example Modal"
				className="modal-language"
			>
				<div className="languages-container">
					<button className="close-modal" onClick={props.closeModal}><img src={iconClose} alt=""/></button>
					<h2>{getText("languages[text_title]")}</h2>
					<div className="language-content">
						<form action="">
							{renderLanguages()}
						</form>
					</div>
				</div>
			</Modal>
			<nav className={props.menuIsOpen ? 'active' : ''}>
				<div className="nav-inner">
					<ul>
						{ renderMenuItems()?.primary }
					</ul>

					<ul className="menu-secondary">
						{ renderMenuItems()?.secondary }
					</ul>
				</div>
			</nav>
		</>
	);
}

export default MenuComponent;
