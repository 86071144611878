import React from 'react';
import getText from '../../utils/language';

import './Transactions.scss';
import clsx from 'clsx';

const TransactionsComponent = (props) => {

	const renderTransactions = () => {
		if(props.transactions.length === 0) {
			return (
				<div className="transaction">
					<p className="no-transactions-text">{getText("transactions[empty]")}</p>
				</div>
			);
		}

		return props.transactions.map((transaction, index) => {
			const intent = transaction.args.pricePoint === 'ZVP_Promo2_MiniApp_DCB_3999' || transaction.args.pricePoint === 'ZVP_Promo2_MiniApp_Vodapay_3999' ? 'Monthly subscription' : 'Daily subscription';
			const event = transaction.eventCode === 'CHARGE_ATTEMPT_SUCCESS' ? 'Successful payment' : 'Failed payment';
			const amount = (transaction.args.amount / 100).toFixed(2);
			const currency = transaction.args.currency;

			const formatedDate = transaction.date.toLocaleString('en-GB', {
				day: 'numeric',
				year: 'numeric',
				month: 'long',
				hour: 'numeric',
				minute: 'numeric'
			}).replace(',', ' |');

			return (
				<div className="transaction" key={index}>
					<p className="date">{formatedDate}</p>
					<p className="amount">{currency} {amount}</p>
					<p className="intent">{intent}</p>
					<p className={clsx("event", transaction.eventCode === 'CHARGE_ATTEMPT_SUCCESS' ? "success" : "fail")}>{event}</p>
				</div>
			);
		})
	}

	return (
		<div className="transactions-component text-pages">
			<h1>{getText("transactions[title]")}</h1>
			{props.transactions && (
				<div className="list">
					{renderTransactions()}
				</div>
			)}
		</div>
	);
};

export default TransactionsComponent;
