import React from 'react';
import clsx from 'clsx';

import './LevelItem.scss';

import IconCheck from '../../resources/img/icon-check.png';

const LevelItem = ({status, icon, title}) => {
	return (
		<div className={clsx("level-item", status)}>
			{status === 'completed' && (
				<>
					<img src={IconCheck} alt="" className="icon-check"/>
					<div className="border-completed"></div>
				</>
			)}

			<div className="level-image">
				<img src={icon} alt=""/>
			</div>
			<h3 className="level-title">{title}</h3>
		</div>
	);
};

export default LevelItem;
