import React, {Component, createRef} from 'react';
import Timer from 'react-compound-timer'

import DragGameComponent from './DragGameComponent';

import '../Timer.scss';

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
	<Timer {...timerProps}>
		{timerRenderProps =>
			<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
	</Timer>
);

class DragGameContainer extends Component {

	constructor(props) {
		super(props);

		let answers = this.props.playGetGame && this.props.playGetGame.data && this.props.playGetGame.data.images ? [this.props.playGetGame.data.images.correct, this.props.playGetGame.data.images.wrong1, this.props.playGetGame.data.images.wrong2] : [];

		this.state = {
			answers: this.shuffle(answers),
			timeFinished: null,
			choice: null
		}

		this.originalRef = createRef();
		this.answersListRef = [];

		props.timer.setCheckpoints([
			{
				time: 0,
				callback: () => {
					this.finishGame();
				},
			},
		]);

		props.timer.setTime(props.playGetGame.data.maxSeconds * 1000 + 500);
		props.timer.start();
	}

	shuffle = (array) => {
		let currentIndex = array.length, temporaryValue, randomIndex;

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {

			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}

	setChoice = (choice) => {
		this.setState({
			choice
		})
	}

	finishGame = () => {
		let time = -1;
		if(this.state.choice === this.props.playGetGame?.data?.images?.correct?.id) {
			time = parseInt(this.props.timer.getTime()/1000) === 0 ? -1 : parseInt(this.props.timer.getTime()/1000);
		}

		this.props.uiRedirect({
			pathname: '/result',
			state: {
				gamePlayId: parseInt(this.props.playStartGame.id),
				time
			}
		})
	}

	render() {
		return (
			<DragGameComponent
				timer={this.props.timer}
				lang={this.props.lang}
				playGetGame={this.props.playGetGame}
				answers={this.state.answers}
				originalRef={this.originalRef}
				answersListRef={this.answersListRef}
				finishGame={this.finishGame}
				setChoice={this.setChoice}
			/>
		);
	}
}

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60500,
	startImmediately: false,
})(DragGameContainer);

<TimerHOC />

export default TimerHOC;
