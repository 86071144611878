import React from 'react';
import getText, { HtmlParser } from 'AppUtils/language';

import GamesSliderContainer from '../subcomponents/games-slider/GamesSliderContainer';
import PrizesBanner from '../../../../common/prizes-banner/PrizesBanner';

import './Games.scss';
import {appGetConfig} from '../../../../store/selectors';

const GamesComponent = (props) => {
	return (
		<div className="play-component">
			<div className="title">
				<h2>
					{props.virtualGames ?
						getText('virtual_games[text_title]')
						:
						props.pregames ?
							getText('pregames[text_title]')
							:
							getText('games[text_title]')
					}
				</h2>
				<p>
					{props.virtualGames ?
						<HtmlParser html="virtual_games[text_subtitle]" />
						:
						props.pregames ?
							<HtmlParser html="pregames[text_subtitle]" />
						:
						<HtmlParser html="games[text_subtitle]" />
					}
				</p>
			</div>
			<GamesSliderContainer
				lang={props.lang}
				games={props.games}
				virtualGames={props.virtualGames}
				pregames={props.pregames}
				userInfoGames={props.userInfoGames}
				handlePlayGame={props.handlePlayGame}
			/>
			<PrizesBanner prize1Image={props.appGetConfig.config.content.media.instantPrizeLogo} prize2Image={props.appGetConfig.config.content.media.grandPrizeLogo} />
		</div>
	);
};

export default GamesComponent;
