import React from 'react';
import getText from 'AppUtils/language';
import {Link} from 'react-router-dom';

import './Credits.scss';

import iconCoin from './img/coin.png';
import priceTag from './img/credits-price-bg.png';
import Button from '../../common/button/ButtonComponent';

const CreditsComponent = (props) => {
	return (
		<div className="credits-component vertical-center">
			{props.creditPackages.length === 0 ? (
				<h2>{getText('credits[credits_buy_disabled]')} <br/>
					{getText('credits[credits_buy_disabled_subtitle]')}</h2>
			) : (
				<h2>{getText('credits[text_title]')} <br/>
					{getText('credits[text_subtitle]')}</h2>
			)}
			<div className="credits-types">
				{props.creditPackages.length > 0 && props.creditPackages.map((credit) => {
					return (<Link key={credit.id} className="credit-button" onClick={(e) => props.onBuy(e, credit.id)} to="#">
						<div className="description">
							<p>
								<i>{getText('playmore[text_buy]')} </i><img src={iconCoin} alt="" /><span>{credit.credits}</span>
							</p>
							<p className="credits-no">{credit.name[props.lang]}</p>
						</div>
						<div className="price">
							<span>{credit.cost[props.lang]}</span>
							<img src={priceTag} alt="" />
						</div>
					</Link>);
				})}
			</div>

			<div className="confirm-btn-wrapper">
				<Button
					onClick={props.handleConfirm}
					fullWidth={true}
				>
					{getText('credits[text_button]')}
				</Button>
			</div>
		</div>
	);
};

export default CreditsComponent;
