import React from 'react';
import getText from '../../utils/language';

import PrizeItem from '../../common/prize-item/PrizeItem';

import './Prizes.scss';

import Prize from './img/gift.png';

import Button from '../../common/button/ButtonComponent';
import {Link} from 'react-router-dom';

const PrizesComponent = ({prizes}) => {
	return (
		<div className="prizes-component">
			<div className="title">
				<h2>{getText("prizes[text_title]")}</h2>
				<p>{getText("prizes[text_subtitle]")}</p>
			</div>
			<img src={Prize} alt="" className="prizes-image"/>
			<div className="prizes">
				<PrizeItem
					variant={"v1"}
					image={prizes.instantPrizeLogo}
					title={getText("homepage[prize_1_title]")}
					description={getText("homepage[prize_1_description]")}
				/>
				<PrizeItem
					variant={"v3"}
					image={prizes.grandPrizeLogo}
					title={getText("homepage[prize_2_title]")}
					description={getText("homepage[prize_2_description]")}
					subtitle={getText("homepage[prize_2_subtitle]")}
				/>
			</div>

			<Link to="/play" className="prizes-btn">
				<Button
					fullWidth={true}
				>
					{getText("prizes[text_btn]")}
				</Button>
			</Link>
		</div>
	);
};

export default PrizesComponent;
