import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getValue} from '../../../../utils/objects';

import Games from './GamesComponent';
import Loader from '../../../../common/loader/Loader';

import {userEvent} from '../../../user/store/selectors';

import {
	playGetExternalGames,
	playGetGames,
	playStartGame,
	playGetPregames,
} from "../../store/selectors";

import {
	playLoadExternalGames,
	playLoadGames,
	playLoadPregames,
	playLoadStartGame,
} from "../../store/actions";
import {uiRedirect} from '../../../../store/actions';
import {userLoadEvent} from '../../../user/store/actions';
import {appGetConfig, appGetInterfaceLang} from '../../../../store/selectors';


const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	playGetGames: playGetGames(state),
	playGetPregames: playGetPregames(state),
	playStartGame: playStartGame(state),
	playGetExternalGames: playGetExternalGames(state),
	userEvent: userEvent(state),
	lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	playLoadPregames: (payload) => dispatch(playLoadPregames(payload)),
	playLoadGames: (payload) => dispatch(playLoadGames(payload)),
	playLoadExternalGames: (payload) => dispatch(playLoadExternalGames(payload)),
	playLoadStartGame: (payload) => dispatch(playLoadStartGame(payload)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
});

@connect(stateToProps, actionsToProps)
class GamesContainer extends Component {

	constructor(props) {
		super(props);

		const extraLife = props.location?.state?.extraLife;

		if(!getValue(props.userInfo, 'session.pregame') || extraLife) {
			this.props.uiRedirect({
				pathname: '/play-pregame',
				state: {
					extraLife
				}
			})
		} else if(getValue(props.userInfo, 'session.pregame.played') && props.location.pathname === '/play-pregame' && !extraLife) {
			this.props.uiRedirect({
				pathname: '/'
			})
		} else if(getValue(props.userInfo, 'session.pregame') && !getValue(props.userInfo, 'session.pregame.played')) {
			this.props.uiRedirect({
				pathname: `/play-pregame/${getValue(props.userInfo, 'session.pregame.id')}`
			})
		}


		this.state = {
			userInfoGames: this.props.userInfo ? this.props.userInfo.games : '',
			pregames: false,
			extraLife
		}
	}

	handlePlayGame = (e, id, gameUrl) => {
		e.preventDefault();
		if(this.state.pregames) {
			this.props.uiRedirect({
				pathname: `/play-pregame/${id}`,
				state: {
					runGame: true,
					extraLife: this.state.extraLife
				}
			})
		} else if(this.state.virtualGames) {
			this.setState({
				redirectTo: gameUrl
			}, () => this.props.userLoadEvent({ type: 'ACTIVITY_START', metadata: `{"activity_session_id": ${Math.floor(Math.random() * 1000)}, "activity_type": 3, "activity_subtype": ${id}}`}))
		} else {
			if(this.props.userInfo.games[id] === 'playable' || this.props.userInfo.games[id] === 'playing') {
				this.props.uiRedirect({
					pathname: `/play/${id}`,
					state: {
						runGame: true,
					}
				})
			}
		}
	}

	componentDidMount() {
		const path = this.props.location.pathname;

		if(path === '/play') {
			if(this.props.userInfo.games) {
				this.props.playLoadGames();
			}
		} else if(path === '/play-virtual') {
			this.setState({
				virtualGames: true
			})
			if(this.props.userInfo.games) {
				this.props.playLoadExternalGames();
			}
		} else if(path === '/play-pregame') {
			this.setState({
				pregames: true
			})
			this.props.playLoadPregames();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const path = this.props.location.pathname;

		if(prevProps.playGetGames !== this.props.playGetGames && this.props.playGetGames && path === '/play') {
			this.setState({
				playGetGames: this.props.playGetGames
			})
		}

		if(prevProps.playGetExternalGames !== this.props.playGetExternalGames && this.props.playGetExternalGames) {
			this.setState({
				playGetGames: this.props.playGetExternalGames
			})
		}

		if(prevProps.playGetPregames !== this.props.playGetPregames && this.props.playGetPregames) {
			this.setState({
				playGetGames: this.props.playGetPregames
			})
		}

		if(prevProps.userInfo !== this.props.userInfo) {

			// if(path === '/play') {
			// 	this.props.playLoadGames();
			// } else if(path === '/play-virtual') {
			// 	this.props.playLoadExternalGames();
			// } else if(path === '/play-pregame') {
			// 	this.props.playLoadPregames();
			// }
			if(prevProps.userInfo.games !== this.props.userInfo.games) {
				this.setState({
					userInfoGames: this.props.userInfo.games
				})
			}
		}

		if(prevProps.userEvent !== this.props.userEvent && this.props.userEvent === 'ACTIVITY_START') {
			window.location.href = this.state.redirectTo;
		}
	}

	render() {
		if(!this.state.playGetGames || !this.state.userInfoGames) {
			return <Loader/>;
		}
		return (
			<Games
				lang={this.props.lang}
				pregames={this.state.pregames}
				virtualGames={this.state.virtualGames}
				games={this.state.playGetGames}
				userInfoGames={this.state.userInfoGames}
				userInfo={this.props.userInfo}
				handlePlayGame={this.handlePlayGame}
				appGetConfig={this.props.appGetConfig}
			/>
		);
	}
}

export default GamesContainer;
