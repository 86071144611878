import React from 'react';
import getText, { HtmlParser } from 'AppUtils/language';

import "../TextPages.scss";

import iconBack from '../../../resources/img/icon-back.png';
import {Link} from 'react-router-dom';

const TermsComponent = (props) => {
    return (
        <div className="text-pages tos-component">
			<div className="page-title">
				<Link to={"/play"}><img src={iconBack} alt="Back"/></Link>
				<h1>{getText("tos[text_title]")}</h1>
			</div>
			<HtmlParser html="tos[text_content]"/>
		</div>
	);
}

export default TermsComponent;
