import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';

import Footer from './FooterComponent';

import {
	authIsAuthenticated
} from '../../auth/store/selectors';
import {appGetConfig, appGetInterfaceLang} from '../../../store/selectors';
import {playGetPregames} from '../../play/store/selectors';
import {userCredits, userInfo} from '../../user/store/selectors';

const stateToProps = state => ({
	authIsAuthenticated: authIsAuthenticated(state),
	appGetConfig: appGetConfig(state),
	userInfo: userInfo(state),
	userCredits: userCredits(state),
	lang: appGetInterfaceLang(state),
});

@connect(stateToProps, null)
class FooterContainer extends Component {

	render() {
		return (
			<Footer
				authIsAuthenticated={this.props.authIsAuthenticated}
				operatorLogo={this.props.appGetConfig.config?.content?.media?.operatorLogo}
				lang={this.props.lang}
				userInfo={this.props.userInfo}
				userCredits={this.props.userCredits}
			/>
		);
	}
}

export default FooterContainer;
