import React, {Component, createRef} from 'react';
import Timer from 'react-compound-timer'
import {shuffle} from '../../../../../utils/arrays';

import FillShapeComponent from './FillShapeComponent';

import '../Timer.scss';
import isEqual from 'lodash-es/isEqual';

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
	<Timer {...timerProps}>
		{timerRenderProps =>
			<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
	</Timer>
);

class FillShapeContainer extends Component {

	constructor(props) {
		super(props);

		let answers = this.props.playGetGame?.data?.images;

		this.state = {
			answers: shuffle(answers),
			timeFinished: null,
			choice: null,
		}

		props.timer.setCheckpoints([
			{
				time: 0,
				callback: () => {
					this.finishGame();
				},
			},
		]);

		props.timer.setTime(props.playGetGame.data.maxSeconds * 1000 + 500);
		props.timer.start();
	}

	setChoice = (choice) => {
		this.setState({
			choice,
		})
	}

	finishGame = () => {
		let time = -1;
		if(this.state.choice.correct) {
			time = parseInt(this.props.timer.getTime()/1000) === 0 ? -1 : parseInt(this.props.timer.getTime()/1000);
		}

		this.props.uiRedirect({
			pathname: '/result',
			state: {
				gamePlayId: parseInt(this.props.playStartGame.id),
				time
			}
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!isEqual(prevState.choice, this.state.choice) && this.state.choice) {
			setTimeout(() => {
				this.finishGame()
			}, 1000)
		}
	}

	render() {
		return (
			<FillShapeComponent
				timer={this.props.timer}
				lang={this.props.lang}
				playGetGame={this.props.playGetGame}
				answers={this.state.answers}
				finishGame={this.finishGame}
				setChoice={this.setChoice}
				choice={this.state.choice}
			/>
		);
	}
}

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60500,
	startImmediately: false,
})(FillShapeContainer);

<TimerHOC />

export default TimerHOC;
