import React from "react";
import getText from '../../utils/language';

import "./PrizesBanner.scss";

const PrizesBanner = ({prize1Image, prize2Image}) => {
	return (
		<div className="prizes-banner">
			<div className="banner-content">
				<div className="prize-banner">
					<div className="prize-banner-image">
						<img src={prize1Image} alt="" />
					</div>
					<div className="prize-banner-info">
						<h3 className="prize-banner-title">{getText("homepage[prize_1_title]")}</h3>
						<p className="prize-banner-description">{getText("homepage[prize_1_description]")}</p>
					</div>
				</div>
				<div className="prize-banner">
					<div className="prize-banner-image">
						<img src={prize2Image} alt=""/>
					</div>
					<div className="prize-banner-info">
						<h3 className="prize-banner-title">{getText("homepage[prize_2_title]")}</h3>
						<p className="prize-banner-description">{getText("homepage[prize_2_description]")}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrizesBanner;
