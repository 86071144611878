import React from 'react';
import { Link } from 'react-router-dom';
import getText from 'AppUtils/language';

import FormField from '../../../../common/form-field/FormFieldComponent';
import Button from '../../../../common/button/ButtonComponent';

import './ForgotPass.scss';

const ForgotPassComponent = (props) => {
	return (
		<div className="forgot-pass-component">
			<form onSubmit={(e) => props.handleSubmit(e)}>
				<div className="inputs-wrapper">
					<div className="shadow-wrapper">
						<FormField
							{...props.formData.phone}
							handleChange={(element) => props.handleInputChange(element)}
							validationMessage={props.formData.phone.validationMessage}
							prefix={props.prefix}
							maxLength={props.msisdn.maxLength ? props.msisdn.maxLength : false}
							rtl={props.rtl}
						/>
					</div>
				</div>

				<button
					type="submit"
				>
					<Button
						fullWidth={true}
					>
						{getText('recover[button_recover]')}
					</Button>
				</button>
			</form>
			<div className="btn-actions">
				<Link to="/">
					<Button
						outline={true}
					>
						{getText('recover[button_cancel]')}
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default ForgotPassComponent;
