import React, {Component} from 'react';
import {connect} from 'react-redux'

import {userLoadTransactions} from '../user/store/actions';

import TransactionsComponent from './TransactionsComponent';
import {userTransactions} from '../user/store/selectors';

const stateToProps = state => ({
	userTransactions: userTransactions(state),
});

const actionsToProps = dispatch => ({
	userLoadTransactions: () => dispatch(userLoadTransactions()),
});

@connect(stateToProps, actionsToProps)
class TransactionsContainer extends Component {

	componentDidMount() {
		this.props.userLoadTransactions();
	}

	render() {
		return (
			<TransactionsComponent
				transactions={this.props.userTransactions?.dcbTransactions}
			/>
		);
	}
}

export default TransactionsContainer;
