import React from 'react';
import { Link } from 'react-router-dom';
import getText from '../../../utils/language';
import clsx from 'clsx';

import Button from '../../../common/button/ButtonComponent';

import './Confirm.scss';

import Trophy from '../../../resources/img/trophy-simple.png';

const ConfirmComponent = (props) => {

	return (
		<div className="confirm-component">
			<div className="title">
				<h2>{getText("confirm[text_title]")}</h2>
				<p>{getText("confirm[text_subtitle]")}</p>
			</div>

			<div className={clsx("prize-confirm", props.prize.points > 0 && "small")}>
				<h3>{props.prize.texts[props.lang][0]}</h3>
				<p>{props.prize.texts[props.lang][1]}</p>
			</div>

			<img src={Trophy} alt="" className="trophy"/>

			<div className="btn-group">
				<Link to="/play">
					<Button
						fullWidth={true}
					>
						{getText('confirm[button_play_now]')}
					</Button>
				</Link>
				{
					props.canReplay ? (
						<Link to="/watch-video">
							<Button
								fullWidth={true}
								inverted={true}
							>
								{getText('confirm[button_extra_life]')}
							</Button>
						</Link>
					) : null
				}
			</div>

			{props.canReplay ? (
				<p className="notice">
					{getText('confirm[notice]')}
				</p>
			) : null}
		</div>
	);
};

export default ConfirmComponent;
