import React, {Component} from 'react';
import {connect} from 'react-redux';

import Confirm from './ConfirmComponent';
import Loader from '../../../common/loader/Loader';

import {appGetInterfaceLang} from '../../../store/selectors';

const stateToProps = state => ({
	lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({

});

@connect(stateToProps, actionsToProps)
class ConfirmContainer extends Component {

	state = {
		prize: this.props.location?.state?.prize
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if(prevProps.userInfo?.session?.prize !== this.props.userInfo?.session?.prize) {
			this.setState({
				prize: this.props.userInfo?.session?.prize
			})
		}
	}



	render() {
		if(!this.state.prize) {
			return (
				<Loader />
			)
		}

		return (
			<Confirm
				lang={this.props.lang}
				prize={this.state.prize}
				canReplay={!this.props.userInfo?.session?.extraLifeAwarded}
			/>
		);
	}
}

export default ConfirmContainer;
