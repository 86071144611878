import React from 'react';
import clsx from 'clsx';

import Signin from '../subcomponents/signin/SigninContainer';
import Signup from '../subcomponents/signup/main/SignupContainer';
import ForgotPass from '../subcomponents/forgot-pass/ForgotPassContainer';
import Restricted from '../subcomponents/restricted/RestrictedContainer';

import './Auth.scss';

const AuthComponent = (props) => {
	return (
		<div className={clsx("auth-component", "vertical-center", {"prefix-active": props.prefix})}>
			<div
				className="prize"
				style={{
					backgroundImage: `url(${props.appGetConfig.config?.content?.media?.grandPrizeLogo})`
				}}
			>
			</div>
			{
				props.authType === 'signin' ?
					<Signin {...props} />
					:
				props.authType === 'signup' ?
					<Signup {...props} />
					:
				props.authType === 'forgot-password' ?
					<ForgotPass {...props} />
					:
				props.authType === 'restricted' ?
					<Restricted {...props} />
					: null
			}
		</div>
	);
};

export default AuthComponent;
