import React, {Component} from 'react';
import {connect} from 'react-redux';
import {appGetConfig, appGetInterfaceLang} from '../../../store/selectors';
import {uiRedirect, uiToggleModal} from '../../../store/actions';
import getText from '../../../utils/language';
import {userLoadInfo} from '../../user/store/actions';

import WatchVideoComponent from './WatchVideoComponent';

import iconHeart from '../../../resources/img/icon-heart.png';

const stateToProps = state => ({
	lang: appGetInterfaceLang(state),
	appGetConfig: appGetConfig(state),
});

const actionsToProps = dispatch => ({
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadInfo: (data) => dispatch(userLoadInfo(data)),
});

@connect(stateToProps, actionsToProps)
class WatchVideoContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			watchedSeconds: new Set(), // Tracks each unique second watched
			isWatchedCompletely: false,
		};
		this.playerRef = React.createRef();
	}

	handleProgress = (progress) => {
		const currentTime = Math.round(progress.playedSeconds); // Use rounding instead of floor

		this.setState((prevState) => {
			const updatedWatched = new Set(prevState.watchedSeconds);
			updatedWatched.add(currentTime); // Add the rounded current second
			return { watchedSeconds: updatedWatched };
		});
	};

	handleVideoEnd = () => {
		const totalDuration = Math.round(this.playerRef.current.getDuration()); // Use rounded duration
		const watchedTime = this.state.watchedSeconds.size; // Total unique seconds watched

		console.log("Watched Time:", watchedTime);
		console.log("Total Duration:", totalDuration);

		// Ensure the last second is included
		if (!this.state.watchedSeconds.has(totalDuration)) {
			this.setState((prevState) => {
				const updatedWatched = new Set(prevState.watchedSeconds);
				updatedWatched.add(totalDuration);
				return { watchedSeconds: updatedWatched };
			}, () => this.validateCompletion(totalDuration));
		} else {
			this.validateCompletion(totalDuration);
		}
	};

	validateCompletion = (totalDuration) => {
		const watchedTime = this.state.watchedSeconds.size; // Recheck after adding the last second

		// Check if at least 95% of the video was watched
		if (watchedTime >= totalDuration * 0.95) {
			this.setState({ isWatchedCompletely: true });

			this.openModal(getText('watch_video[modal_success_title]'), getText('watch_video[modal_success_message]'), true, getText('watch_video[button_ok]'), false, "modal-watch-video", iconHeart, () => {
				this.props.uiToggleModal({}, { toggle: false }, {} );
				userLoadInfo();
				this.props.uiRedirect({
					pathname: '/play-pregame',
					state: {extraLife: true}
				});
			});
		}
	};

	openModal = (title, message, button, buttonText, restrictClose, extraClass, icon, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, restrictClose, extraClass, icon, callback } );
	};

	closeModal = () => {
		this.props.uiToggleModal({}, { toggle: false }, { title: '', message: '' });
	};

	render() {
		return (
			<WatchVideoComponent
				playerRef={this.playerRef}
				handleProgress={this.handleProgress}
				handleVideoEnd={this.handleVideoEnd}
				videoCode={this.props.appGetConfig?.videos[0]?.code}
			/>
		);
	}
}

export default WatchVideoContainer;
