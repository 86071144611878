import React from 'react';
import Header from '../layout/header/main/HeaderContainer';
import Footer from '../layout/footer/FooterContainer';

const Layout = (props) => {
	return (
		<>
			<Header />
			<main className="content">
				{props.children}
			</main>
			{!props.hideFooter ? (
				<Footer />
			) : null}

		</>
	);
};

export default Layout;
