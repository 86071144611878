import React from 'react';
import ReactPlayer from 'react-player';
import getText from '../../../utils/language';

import Button from '../../../common/button/ButtonComponent';

import {Link} from 'react-router-dom';
import './WatchVideo.scss';

const WatchVideoComponent = (props) => {
	return (
		<div className={"watch-video-component"}>
			<div className="title">
				<h2>{getText("watch_video[text_title]")}</h2>
				<p>{getText("watch_video[text_subtitle]")}</p>
			</div>

			<div className={"video-wrapper"}>
				<ReactPlayer
					ref={props.playerRef}
					height={"100%"}
					width={"100%"}
					url={`https://www.youtube.com/watch?v=${props.videoCode}`}
					controls={false}
					onProgress={props.handleProgress}
					onEnded={props.handleVideoEnd}
					style={{position: "absolute", top: 0, left: 0}}
				/>
			</div>

			<div className="btn-group">
				<a href="https://www.vdc-entertainment.com/ZVN-ZAVOD-UPSTR/glow1-en-soi-wifi?nowifi=1&HEKeyword=ZVN_GLOW_PORTAL_1&utm_source=portal&utm_medium=cpa&utm_content=glow1&utm_campaign=ZVN_GLOW_PORTAL_1-portal-web-cpa-glow1-image"
				   target={"_blank"}>
					<Button
						fullWidth={true}
					>
						{getText('watch_video[btn_subscribe]')}
					</Button>
				</a>
				<Link to={"/play"}>
					<Button
						inverted={true}
						fullWidth={true}
					>
						{getText('watch_video[btn_skip]')}
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default WatchVideoComponent;
