import React, {Component} from "react";
import GamesSliderComponent from './GamesSliderComponent';

class GamesSliderContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav1: null,
			nav2: null,
		};
		this.slider1 = null;
		this.slider2 = null;
	}

	componentDidMount() {
		this.setState({
			nav1: this.slider1,
			nav2: this.slider2,
		});
	}

	render() {
		return (
			<GamesSliderComponent
				{...this.props}
				nav1={this.state.nav1}
				nav2={this.state.nav2}
				setSlider1={(slider) => (this.slider1 = slider)}
				setSlider2={(slider) => (this.slider2 = slider)}
			/>
		);
	}
}

export default GamesSliderContainer;
