import React from 'react';
import getText, { HtmlParser } from 'AppUtils/language';
import Countdown from 'react-countdown';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

import AvatarProgress from '../../../common/avatar-progress/AvatarProgressComponent';
import Button from '../../../common/button/ButtonComponent';

import './Results.scss';

import iconNewbie from '../../../resources/img/icons-level/icon-newbie.png';
import iconPro from '../../../resources/img/icons-level/icon-pro.png';
import iconSkilled from '../../../resources/img/icons-level/icon-skilled.png';
import iconGuru from '../../../resources/img/icons-level/icon-guru.png';
import trophy from '../../../resources/img/trophy-simple.png';
import stars from './img/stars.png';

const ResultComponent = (props) => {
	let iconLevel = '';
	let textLevel = '';

	switch(props.level) {
		case 1:
			iconLevel = iconNewbie;
			textLevel = getText('global[newbie]');
			break;
		case 2:
			iconLevel = iconPro;
			textLevel = getText('global[pro]');
			break;
		case 3:
			iconLevel = iconSkilled;
			textLevel = getText('global[skilled]');
			break;
		case 4:
			iconLevel = iconGuru;
			textLevel = getText('global[guru]');
			break;
	}

	return (
		<div className="result-component">
			<>
				<div className="title">
					<h2>{props.playFinishGame.result ? getText('congratulations[text_title]') : getText('sorry[text_title]')}</h2>
					{props.playFinishGame.result ?
						<p>{getText('congratulations[text_subtitle]')}</p>
						:
						props.playFinishGame.result ?
							null
							: <p><HtmlParser html="sorry[text_subtitle]"/></p>
					}

				</div>
				<div className={clsx("prize-results", props.playFinishGame.session.points > 0 && "small")}>
					<h3>{props.playFinishGame.session.prize.texts[props.lang][0]}</h3>
					<p>{props.playFinishGame.session.prize.texts[props.lang][1]}</p>
				</div>

				{props.playFinishGame.result ? (
					<div className="level-results">
						<img src={stars} alt="" className="bg-level"/>
						<img src={iconLevel} alt="" className="level-image" />
						<p className="level-text">{textLevel}</p>
						<span>{props.totalPoints} {getText('congratulations[text_total_points]')}</span>
					</div>
				) : (
					<div className="trophy-results">
						<img src={trophy} alt=""/>
						<p>{getText('sorry[text_trophy]')}</p>
					</div>
				)}

				{/*<div className="total-points">*/}
				{/*	<span*/}
				{/*		className="gained-points">+{props.playFinishGame ? props.playFinishGame.points : '0'}</span>*/}
				{/*	<h3 className="points">{props.totalPoints}</h3>*/}
				{/*	<p>{getText('congratulations[text_total_points]')}</p>*/}
				{/*</div>*/}
				<Link
					to={props.session.open ? "/play" : (props.scheme === "credits" && props.credits === 0 ? "/credits" : "/play")}
					className={clsx({"btn-again": true, inactive: !props.session.open})}
					onClick={(e) => {
						if (!props.session.open && props.scheme !== "credits") {
							e.preventDefault();
						}
					}}
				>
					<Button
						fullWidth={true}
					>
						{props.session.open ?
							getText('congratulations[button_play_next_game]')
							:
							<>
								{props.scheme === 'credits' ?
									<>
										{getText('congratulations[button_play_again_for]')}
									</>
									:
									<>
										{getText('congratulations[button_play_again_in]')}
										<span className="countdown">
												<Countdown
													date={props.session ? props.session.playAgain : 0}
													onComplete={() => props.handleCountdown()}
													daysInHours={true}
												/>
											</span>
									</>
								}
							</>
						}
					</Button>
				</Link>
				{/*<Link to="/play-virtual" className="btn-next">*/}
				{/*	<Button*/}
				{/*		fullWidth={true}*/}
				{/*		boxShadow={true}*/}
				{/*	>*/}
				{/*		{getText('congratulations[button_play_virtual_game]')}*/}
				{/*	</Button>*/}
				{/*</Link>*/}
			</>

			{/*<AvatarProgress />*/}
		</div>
	);
};

export default ResultComponent;
