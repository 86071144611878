import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import getText, { HtmlParser } from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';

import Button from '../../common/button/ButtonComponent';
import PrizeItem from '../../common/prize-item/PrizeItem';
import LevelsGrid from '../../common/levels-grid/LevelsGrid';

import './About.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from "../../resources/img/logo.png";
import Trophy from "../../resources/img/trophy.png";

const AboutComponent = (props) => {
	const showOnboarding = getValue(props, 'user.eligibility.scheme') === 'credits' ? getValue(props, 'user.eligibility.eligible') : true;
	const buyCredits =  getValue(props, 'user.eligibility.scheme') === 'credits' && getValue(props, 'user.eligibility.credits') === 0 && !getValue(props, 'user.session.open');

	// Render the component depending on if credit packages are available
	// If we have credit packages, button should redirect to credits page
	// If we don't have credit packages, button shouldn't do anything
	const renderNotEligibleBtn = () => {
		const button = (
			<Link to="/credits" className="btn-slider btn-warning">
				<Button
					fontSize={12}
					fullWidth={true}
				>
					<span className="warning-text">{getText("homepage[text_not_elligible_1]")}</span>
					<span>{getText("homepage[text_not_elligible_2]")}</span>
				</Button>
			</Link>
		);

		return button;

		// if (props.creditPackages.length > 0) {
		// 	return (
		// 		<Link to="/credits" className="btn-slider btn-warning">
		// 			{button}
		// 		</Link>
		// 	);
		// } else {
		// 	return (
		// 		<div className="btn-slider btn-warning">
		// 			{button}
		// 		</div>
		// 	);
		// }
	}

	return (
		<div className="about-component">
			<img src={logo} alt="" className="logo"/>
			<div className="about-slider">
				<Slider {...props.settings} ref={props.sliderRef}>
					<div className="item first-item">
						<p className="slide-header"><HtmlParser html="homepage[text_slide_1_header]" /></p>
						<p className="slide-description"><HtmlParser html="homepage[text_slide_1]" /></p>
						<div className="prizes">
							<PrizeItem
								variant={"v1"}
								image={props.prizes.instantPrizeLogo}
								title={getText("homepage[prize_1_title]")}
								description={getText("homepage[prize_1_description]")}
							/>
							<PrizeItem
								variant={"v2"}
								image={props.prizes.grandPrizeLogo}
								title={getText("homepage[prize_2_title]")}
								description={getText("homepage[prize_2_description]")}
							/>
						</div>
					</div>
					<div className="item second-item">
						<p className="slide-header"><HtmlParser html="homepage[text_slide_2_header]"/></p>
						<p className="slide-description"><HtmlParser html="homepage[text_slide_2]"/></p>
						<div className="trophy">
							<img src={Trophy} alt="Trophy"/>
						</div>
					</div>
					<div className="item third-item">
						<p className="slide-header"><HtmlParser html="homepage[text_slide_3_header]"/></p>
						<p className="slide-description"><HtmlParser html="homepage[text_slide_3]"/></p>
						<LevelsGrid levels={props.levels} lang={props.lang} />
					</div>
				</Slider>
			</div>
			{showOnboarding && <Link to="/play" className="btn-slider"
									 onClick={e => props.lang === 'ar' ? props.handleNextLtr(e) : props.handleNext(e)}>
			<Button
					fullWidth={true}
				>
					{props.lang === 'ar' ? (props.slideIndex === 0 ? getText("homepage[button_start]") : getText("homepage[button_next]")) : (props.slideIndex === 2 ? getText("homepage[button_start]") : getText("homepage[button_next]")) }
				</Button>
			</Link>}
			{buyCredits && renderNotEligibleBtn()}
		</div>
	);
};

export default AboutComponent;
