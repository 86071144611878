import React, {Component} from 'react';
import {connect} from 'react-redux';

import SpinTheWheelComponent from './SpinTheWheelComponent';

import { uiRedirect } from '../../../../../store/actions';
import {playGetPlayPregame} from '../../../store/selectors';
import {playLoadPlayPregame} from '../../../store/actions';

import media_wheel from '../../../../../resources/sounds/media_wheel.mp3';

const stateToProps = state => ({
	playGetPlayPregame: playGetPlayPregame(state)
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	playLoadPlayPregame: (payload) => dispatch(playLoadPlayPregame(payload)),
});

@connect(stateToProps, actionsToProps)
class SpinTheWheelContainer extends Component {

	audioWheel = new Audio(media_wheel);

	constructor(props) {
		super(props);

		const selectedPrize = props.playGetPregame.prizes.filter((prize, index) => prize.id === props.prizeId)?.[0];

		this.state = {
			selectedItem: null,
			playGetPregame: props.playGetPregame,
			prizeId: props.prizeId,
			prize: selectedPrize,
			spinDuration: 8,
			showPrize: false
		};
		this.selectItem = this.selectItem.bind(this);

		if(!props.playGetPregame) {
			props.uiRedirect({
				pathname: '/'
			})
		}
	}

	selectItem() {
		if (this.state.selectedItem === null) {
			this.audioWheel.play();
			const selectedItem = this.getPrizeIndexWithId();
			if (this.props.onSelectItem) {
				this.props.onSelectItem(selectedItem);
			}
			this.setState({ selectedItem }, () => {
				setTimeout(() => {
					this.setState({
						showPrize: true
					})
				}, (this.state.spinDuration + 0.5) * 1000)

				setTimeout(() => {
					this.props.playLoadPlayPregame({id: this.state.playGetPregame.id});
				}, (this.state.spinDuration + 2) * 1000)
			});
		}
	}

	getPrizeIndexWithId = () => {
		let prize = this.state.playGetPregame.prizes.filter((prize, index) => prize.id === this.state.prizeId);
		return this.state.playGetPregame.prizes.indexOf(prize[0]);
	}

	componentDidMount() {
		this.audioWheel.pause();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.prizeId !== prevProps.prizeId) {
			const selectedPrize = this.props.playGetPregame.prizes.filter((prize, index) => prize.id === this.props.prizeId)?.[0];

			this.setState(
				{
					prizeId: this.props.prizeId,
					prize: selectedPrize
				}
			)
		}

		if(prevProps.playGetPlayPregame !== this.props.playGetPlayPregame && this.props.playGetPlayPregame) {
			this.props.uiRedirect({
				pathname: '/confirm',
				state: {
					prize: this.state.prize
				}
			})
		}
	}

	render() {

		return (
			<SpinTheWheelComponent
				lang={this.props.lang}
				spinDuration={this.state.spinDuration}
				playGetPregame={this.state.playGetPregame}
				selectedItem={this.state.selectedItem}
				selectItem={this.selectItem}
				showPrize={this.state.showPrize}
				prize={this.state.prize}
			/>
		);
	}
}

export default SpinTheWheelContainer;
