import React from 'react';
import getText, { HtmlParser } from 'AppUtils/language';

import "../TextPages.scss";
import {Link} from 'react-router-dom';
import iconBack from '../../../resources/img/icon-back.png';

const FaqComponent = (props) => {
    return (
		<div className="text-pages faq-component">
			<div className="page-title">
				<Link to={"/play"}><img src={iconBack} alt="Back"/></Link>
				<h1>{getText("faq[text_title]")}</h1>
			</div>
			<HtmlParser html="faq[text_content]"/>
		</div>
	);
}

export default FaqComponent;
