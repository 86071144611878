import React from 'react';
import clsx from 'clsx';

import ScratchCard from '../subcomponents/ScratchCard';

import './ScratchCards.scss';

import trophy from '../../../../../../resources/img/trophy-no-shadow.png';

const ScratchCardsComponent = (props) => {

	const getSettings = (index) => {
		return {
			width: 120,
			height: 120,
			finishPercent: 70,
			onComplete: () => props.handleScratchComplete(),
			onMouseDown: () => props.handleScratch(index),
		}
	};

	const renderCard = () => {
		return props.playGetPregame.prizes.map((prize, index) => (
			<li key={index} className={clsx((index !== props.startScratchIndex && props.startScratchIndex) && "not-win")}>
				<div className="scratch-card-wrapper" style={{ backgroundColor: "#FFFFFF" }}>
					<ScratchCard {...getSettings(index)} image={props.playGetPregame.data.cards[index]} disabled={(index !== props.startScratchIndex && props.startScratchIndex)}>
						<div className="prize">
							<img src={trophy} alt=""/>
							<p>{props.prize.texts[props.lang][0]}</p>
							<span>{props.prize.texts[props.lang][1]}</span>
						</div>
					</ScratchCard>
				</div>
			</li>
		))
	}

	return (
		<div className="scratch-pregame">
			<div className="title">
				<h2>{props.playGetPregame.name[props.lang]}</h2>
				<p>{props.playGetPregame.subtitle[props.lang]}</p>
			</div>

			<div className="game-wrapper">
				<ul className="cards-list">
					{renderCard()}
				</ul>
			</div>
		</div>
	);
};

export default ScratchCardsComponent;
