import React from 'react';

import './PrizeItem.scss';
import clsx from 'clsx';

const PrizeItem = ({variant, image, title, description, subtitle}) => {
	return (
		<div className={clsx("prize-item", variant)}>
			<div className="prize-image">
				<img src={image} alt={title} />
			</div>
			<div className="prize-info">
				<h3 className="prize-title">{title}</h3>
				{variant === "v3" && (
					<p className="prize-subtitle">{subtitle}</p>
				)}
				<p className="prize-description">{description}</p>
			</div>
		</div>
	);
};

export default PrizeItem;
