import React from 'react';

import Quiz from '../subcomponents/quiz/Quiz';
import MemoryGame from '../subcomponents/memory-game/MemoryGame';
import PuzzleGameContainer from '../subcomponents/puzzle-game/PuzzleGameContainer';
import DragGameContainer from '../subcomponents/drag-game/DragGameContainer';
import DifferencesContainer from '../subcomponents/differences/DifferencesContainer';

import './GameDetails.scss';
import FillShapeContainer from '../subcomponents/fill-shape/FillShapeContainer';

const GameDetailsComponent = (props) => {
	if(props.playGetGame) {
		return (
			<div className="game-details-component">
				{
					props.gameId === 1 ?
					<MemoryGame playGetGame={props.playGetGame} playStartGame={props.playStartGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					props.gameId === 2 ?
					<DifferencesContainer playGetGame={props.playGetGame} playStartGame={props.playStartGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					props.gameId === 3 ?
					<DragGameContainer playGetGame={props.playGetGame} playStartGame={props.playStartGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					props.gameId === 4 ?
					<PuzzleGameContainer playGetGame={props.playGetGame} playStartGame={props.playStartGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					props.gameId === 5 ?
					<Quiz playGetGame={props.playGetGame} playStartGame={props.playStartGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					props.gameId === 6 ?
					<FillShapeContainer playGetGame={props.playGetGame} playStartGame={props.playStartGame} lang={props.lang} uiRedirect={props.uiRedirect} />
					:
					null
				}
			</div>
		);
	} else {
		return null;
	}

};

export default GameDetailsComponent;
