import React from 'react';
import {Link} from 'react-router-dom';
import getText from '../../utils/language';

import LevelsGrid from '../../common/levels-grid/LevelsGrid';
import Button from '../../common/button/ButtonComponent';

import './Levels.scss';

import Flag from './img/flag.png';

const LevelsComponent = (props) => {
	return (
		<div className="levels-component">
			<div className="title">
				<h2>{getText("levels[text_title]")}</h2>
				<p>{getText("levels[text_subtitle]")}</p>
			</div>
			<img src={Flag} alt="" className="levels-image"/>
			<LevelsGrid currentLevel={props.user.level} levels={props.levels} />

			<Link to="/play" className="levels-btn">
				<Button
					fullWidth={true}
				>
					{getText("levels[text_btn]")}
				</Button>
			</Link>
		</div>
	);
};

export default LevelsComponent;
